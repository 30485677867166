import React from "react";

import Footer from "./footer";
import "../assets/css/LandingPage.css";

const LandingPage = () => {
  return (
    <div className="limiterafiliados">

<div  className="sitecanvacursos">
  <iframe loading="lazy" className="inframecanvacursos"
    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFw0V3iNi0&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
  </iframe>
</div>
     
     
      <Footer />
    </div>
  );
};

export default LandingPage;
