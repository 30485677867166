import React, { useEffect, useState } from "react";

import Modal from "react-modal";

import DarkModeToggle from "react-dark-mode-toggle";
import "../assets/css/HeaderWebsite.css";
Modal.setAppElement("#root"); // Certifique-se de substituir '#root' pelo ID do elemento raiz do seu aplicativo

const getThemeFromStorage = () => {
  let theme = true;
  if (localStorage.getItem("theme")) {
    theme = JSON.parse(localStorage.getItem("theme"));
  }
  return theme;
};

function HeaderCalculator(props) {
  const { statusMode, setStatusMode, onLogout, isLoggedIn, showLogoutButton } =
    props;
  const logopreto = `${process.env.PUBLIC_URL}/LOGOPRETO.png`;
  
  const logobranca = `${process.env.PUBLIC_URL}/LOGOBRANCO.png`;

  const [isDarkMode, setIsDarkMode] = useState(getThemeFromStorage());

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.className = "light-theme";
    } else {
      document.documentElement.className = "dark-theme";
    }
    localStorage.setItem("theme", isDarkMode);
  }, [isDarkMode]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const shouldShowLogoutButton = () => {
    const hash = window.location.hash;

    // Verifica se a rota atual é exatamente "/" ou não possui hash (caso padrão)
    if (hash === "" || hash === "/") {
      return true;
    }

    // Verifica se a rota atual contém "/calculator/"
    if (hash.includes("/calculator/")) {
      return false;
    }

    // Obtenha a rota atual
    const currentPath = window.location.pathname;

    // Verifique se a rota atual é exatamente "/"
    if (currentPath === "/") {
      return true;
    }

    // Verifique se a rota atual contém "/calculator/"
    if (currentPath.includes("/calculator/")) {
      return false;
    }

    // Em todos os outros casos, mostra o botão de logout
    console.log("Mostrar botão de logout");
    return true;
  };

  const [volume, setVolume] = useState(0.5);

  useEffect(() => {}, [volume]);

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
  };

  const changeStatusMode = (oldStatusMode) => {
    setStatusMode(!oldStatusMode);

    window.localStorage.setItem("statusMode", JSON.stringify(!oldStatusMode));
    const dataToJson = JSON.stringify(!oldStatusMode);
    localStorage.setItem("statusMode", dataToJson);
    !oldStatusMode
      ? (document.body.style.backgroundColor = "#0d0d0d")
      : (document.body.style.backgroundColor = "");
  };

  return (
    <header
      className={`header d-flex justify-content-between align-items-center navbar navbar-expand-lg navbar-dark ResponsivHeader ${
        statusMode ? "dark-mode" : ""
      }`}
    >
      {/* Inicio da Logo*/}
      <div className="fw-semibold d-flex align-items-center">
        <a
          className={`text-decoration-none fs-5 me-3 ${
            statusMode ? "textDark" : "text-light"
          }`}
          href="/"
        >
          <img
            src={statusMode ? logobranca : logopreto}
            alt="Logo"
            style={{ height: "37px", width: "auto" }}
            className="imgfullhd"
          />
        </a>
      </div>
      {/* Fim da Logo*/}

      <div className="d-flex align-items-center"></div>
    </header>
  );
}

export default HeaderCalculator;
