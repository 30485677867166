import { CommonCompenentDataMarket, CompenentDataMarketFirstSecondExtraTime,  CompenentDataMarketDefault, CompenentDataMarketDynamic} from "./GlobalComponents"


export const orderLoop = ["first_home_bet", "second_home_bet", "third_home_bet", "fourth_home_bet"];

export const listSortType = ['Lucro', 'Início', 'Recentes'];
export const listSortTypeToSelect = { 'início': 'Início', 'recentes': 'Recentes'};

export const NameBetHomeRenamed = {
    "betano(br)": "Betano",
    "vbet(lat)": "Vbet",
    "bodog(en)": "Bodog",
}

export const managerClonerBetHome = {
  'allBetHomeAbleToClone': [
    "sportingbet",
    "betnacional",
    "estrelabet",
    "pixbet",
    "bolsadeaposta",
    "pinnacle",
    'vaidebet'
  ],

  'sportingbet': {
    'allBetHomeClone': ['betboo', 'bwin'],
    'betboo': {
      'baseURL': 'https://sports.br.betboo.com',
      'nameBetHome': 'Betboo',
    },
    'bwin': {
      'baseURL': 'https://sports.bwin.com',
      'nameBetHome': 'Bwin',
    }
  },
  'betnacional': {
    'allBetHomeClone': ['mrjack'],
    'mrjack': {
      'baseURL': 'https://mrjack.bet',
      'nameBetHome': 'MrJack',
    },
  },
  'estrelabet': {
    'allBetHomeClone': ['kto'],
    'kto': {
      'baseURL': 'https://www.kto.com',
      'nameBetHome': 'KTO',
    },
  },
  'pixbet': {
    'allBetHomeClone': ['amuletobet'],
    'amuletobet': {
      'baseURL': 'https://www.amuletobet.com/pt-BR',
      'nameBetHome': 'AmuletoBet',
    },
  },
  'bolsadeaposta': {
    'allBetHomeClone': ['betfair ex', 'bet-bra', 'fulltbet'],
    'betfair ex': {
      'baseURL': 'https://www.betfair.com/exchange/plus/football/market/',
      'nameBetHome': 'Betfair EX',
    },
    'bet-bra': {
      'baseURL': 'https://bet-bra.com/exchange/sport/1/market/',
      'nameBetHome': 'Bet-Bra',
    },
    'fulltbet': {
      'baseURL': 'https://fulltbet.com/exchange/sport/1/market/',
      'nameBetHome': 'FulltBet',
    },

  },

  'pinnacle': {
    'allBetHomeClone': ['bolsadeaposta sb', 'bet-bra sb', 'fulltbet sb'],
    'bolsadeaposta sb': {
      'baseURL': 'https://sports.bolsadeaposta.com',
      'nameBetHome': 'BolsaDeAposta SB',
    },
    'bet-bra sb': {
      'baseURL': 'https://sports2.bet-bra.com',
      'nameBetHome': 'Bet-Bra SB',
    },
    'fulltbet sb': {
      'baseURL': 'https://sports.fulltbet.com',
      'nameBetHome': 'FulltBet SB',
    },
  },

  'vaidebet': {
    'allBetHomeClone': ['betpix365', 'esportesdasorte'],
    'betpix365': {
      'baseURL': 'https://betpix365.com',
      'nameBetHome': 'BetPix365',
    },
    'esportesdasorte': {
      'baseURL': 'https://www.esportesdasorte.com',
      'nameBetHome': 'EsportesDaSorte',
    },
  }
}

export const listBetHomeDemarkOnFilter = [
    [
        'betnacional',
        'mrjack',
    ],
    [
        'estrelabet',
        'kto',
    ],
    [
      'pixbet',
      'amuletobet'
    ],
    [
      'bolsadeaposta', 
      'betfair ex',
      'bet-bra', 
      'fulltbet'
    ],
    [
      'pinnacle',
      'bolsadeaposta sb',      
      'bet-bra sb', 
      'fulltbet sb'
    ],
    [
      'vaidebet',
      'betpix365',
      'esportesdasorte',
    ]
]



export const styleDataMarket ={

    'common_data_market':{
      'componentFunction': function(infoDataMarket, description){ 
        
        return(
          <CommonCompenentDataMarket 
          infoDataMarket={infoDataMarket}
          description={description}
          />
        )  
      }
    },
    'first_second_extra_time':{
      'componentFunction': function(infoDataMarket, description){ 
        
        return(
          <CompenentDataMarketFirstSecondExtraTime 
          infoDataMarket={infoDataMarket}
          description={description}
          />
        )  
      }
    },
  
    'default_data_market':{
        'componentFunction': function(infoDataMarket, description){ 
        
      return(
        <CompenentDataMarketDefault 
        infoDataMarket={infoDataMarket}
        description={description}
        />
      )  
    },
    },

    'dynamic_market':{
        'componentFunction': function(infoDataMarket, description){ 
        
      return(
        <CompenentDataMarketDynamic 
        infoDataMarket={infoDataMarket}
        description={description}
        />
      )  
    },
    }
  }


export const timeSurebetAllowed = 900000;

//------📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱CALCULATOR📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱

export const partInfoOddSurebetJSON = '{"percent":0,"typeBet":"normal"}';


export const modelPopUpSettings = {
  'popupWindowAll':{
    'width': 800,
    'height': 460,
    'top': 0,
    'left': 0,
  },
  'popupWindowAll2':{
    'width': 800,
    'height': 460,
    'top': 0,
    'left': 0,
  },
}