import Cookies from 'js-cookie';
import { listKeysToCheck, listValueItemAccepted, listKeyItemsToUpdateInLocalStorage } from '../variables/VariablesFilterSurebet';



function modelFilter(data) {
    let listKeyEnabledToUse = []
    if (typeof (data) === 'object') {
        let checkList = listKeysToCheck.filter(item => {
            return (data[item] !== undefined);
        });

        listKeyEnabledToUse = checkList.filter(key => {
            let typeVariableAccepted = listValueItemAccepted[key];
            let valueVariableInsideFilter = data[key];
            if (typeof (valueVariableInsideFilter) === typeVariableAccepted) {
                return key
            }
        });
    }
    return listKeyEnabledToUse
}
export async function SaveFilterArbitragem(dataFilterToSave) {
    const b4726486 = Cookies.get('b4726486');
    const dataToSend = {
        's377466043': b4726486,
        'f45837': dataFilterToSave
    };
    const response = await fetch(`https://arbitragem.bet:1180/save_filter`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}




export async function getFilterArbitragem(
    setBetHomeEnabled, setSportsEnabled, setActualFilterOrder, setActualTimeEvent, setActualValueMin, setActualValueMax,
    setQuantityBetHomeEnabled, setQuantitySportsEnabled
) {
    const b4726486 = Cookies.get('b4726486');
    const dataToSend = {
        's377466043': b4726486,
    };


    let listSetState = {
        "betHome": setBetHomeEnabled,
        "sports": setSportsEnabled,
        "timeEvent": setActualTimeEvent,
        "filterOrder": setActualFilterOrder,
        "minProfit": setActualValueMin,
        "maxProfit": setActualValueMax,
    }
    let listQuantityToUpdate ={
    "listBehomeEnabled": setQuantityBetHomeEnabled,
    "listSportsEnabled": setQuantitySportsEnabled,
    }

    

    const response = await fetch(`https://arbitragem.bet:1180/get_filter_arbitragem`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
        try {
            const data = await response.json();
            
            let allKeysFilter = modelFilter(data);

            for (let i = 0; i < allKeysFilter.length; i++) {
                let keyFilter = allKeysFilter[i];
                let keyLocalStorage = listKeyItemsToUpdateInLocalStorage[keyFilter];
                let actualValue = data[keyFilter];

                listSetState[keyFilter](actualValue);

                localStorage.setItem( keyLocalStorage, JSON.stringify(actualValue) );

                if (keyLocalStorage in listQuantityToUpdate){                    
                    listQuantityToUpdate[keyLocalStorage]( actualValue.length );
                }
            }
            return data;
        }
        catch {
            console.log('')
        }
    } else {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}