function converterStringToNumber(valueString) {
  let valueToReturn = {'isNaN': false, 'value': 0}
  let valueConverted = Number(valueString);
  let valueIsNaN = isNaN(valueConverted);
  
  if(valueIsNaN === true){
    valueToReturn['isNaN'] = true;
    return valueToReturn;
  }
  valueToReturn['value'] = valueConverted;
  return valueToReturn;
}

export function formulaLay(odd, valuePercent){
      
    let referencelOdd = odd;            
    let percent = valuePercent * 0.01;                  
    let partOdd = referencelOdd / (referencelOdd - 1);      
    let realOdd = partOdd * (1 - percent) + percent;
    
    return realOdd;
  }
  
export function formulaA3(odd, valuePercent){
    let percentMultiplied = 0.01 * valuePercent;
    let realOdd = odd * (1 - percentMultiplied) + percentMultiplied;
    
    return realOdd;
  }


export function eventPressButtonUpDownIncreaseDecreaseValue(event, functionUpdateState, valueToIncrementDecrement = 1) {
    const { value } = event.target;
  
  
    let valueModified = Number(value.replaceAll(',', '.'));
  
    if (!isNaN(valueModified)) {
      if (event.key === 'ArrowUp') {
        let z = valueModified + valueToIncrementDecrement;
        functionUpdateState(z);
      }
      else if (event.key === 'ArrowDown') {
        let z = valueModified - valueToIncrementDecrement;
        functionUpdateState(z);
    }
  
  }
}


export function setStateAllAdditionalInfoSurebet(value, valuePercent, oldAllAdditionalInfoSurebet, index, setState, capsuleFunction, oldInfoCalcFrontEnd, setStateInfoCalcFrontEntJSON) {
  oldAllAdditionalInfoSurebet[index]['percent'] = valuePercent; /* resultValueConvert['value']*/
  let newAllAdditionalInfoSurebetJSON = JSON.stringify(oldAllAdditionalInfoSurebet);

  oldInfoCalcFrontEnd['commission'][index] = value;
  oldInfoCalcFrontEnd['allStatusComma']['commission'][index] = value.includes(',');
  setStateInfoCalcFrontEntJSON(JSON.stringify(oldInfoCalcFrontEnd));

  setState(newAllAdditionalInfoSurebetJSON);
  capsuleFunction(newAllAdditionalInfoSurebetJSON)
}

export function changeValueAllAdditionalInfoSurebet(value, oldAllAdditionalInfoSurebet, index, setState, capsuleFunction, oldInfoCalcFrontEnd, setStateInfoCalcFrontEntJSON){  
  let valueModified = value.replaceAll(',', '.');

  let resultValueConvert = converterStringToNumber(valueModified);
  
  if(!resultValueConvert['isNaN'] === true){    
    setStateAllAdditionalInfoSurebet(value, resultValueConvert['value'], oldAllAdditionalInfoSurebet, index, setState, capsuleFunction, oldInfoCalcFrontEnd, setStateInfoCalcFrontEntJSON)
  }  
} 


export function finderRealOdd(index, odds, actualAllAdditionalInfoSurebetJSON) {
  let allAdditionalInfoSurebet = JSON.parse(actualAllAdditionalInfoSurebetJSON);
  let allAdditionalInfoSurebetFragmented = allAdditionalInfoSurebet[index];

  let percent = allAdditionalInfoSurebetFragmented['percent'];
  let typeBet = allAdditionalInfoSurebetFragmented['typeBet']
  let odd = odds[index];

  if(typeBet === 'lay'){
    return formulaLay(odd, percent);
  }
  return formulaA3(odd, percent);  
}