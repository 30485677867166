let keysToSkip = [
    'form',
'data-markers',
'url_bet_home',
'data-links',
'time_event',
'date_event',
];

export default function GeneratorLinkSurebet(dataSurebet, profit) {
    let data_markers = dataSurebet['data-markers'];
    let dataLinks = dataSurebet['data-links'];
    let keysDataSurebet = Object.keys(dataSurebet);
    let dataExtension = {};
    dataExtension['profit'] = profit;

    for(let i = 0; i < keysDataSurebet.length; i++){
        let keySurebetExtension = keysDataSurebet[i];
        if ( keysToSkip.indexOf(keySurebetExtension) === -1){
            dataExtension[keySurebetExtension] = dataSurebet[keySurebetExtension];
        }

    }

    let dataExtensionJson = JSON.stringify(dataExtension);
    let encodedDataArb = encodeURIComponent(dataExtensionJson);

    let strUrl = `/nav/arb/prong/0/abcdefg/if?data-arb=${encodedDataArb}&data-links=${dataLinks}&data-markers=${data_markers}`;

    return strUrl;
}