import React from "react";
import YouTube from "react-youtube";
import "../assets/css/Afiliado.css";
import Footer from "./footer";

const videoOpts = {
  playerVars: {
    autoplay: 0,
  },
};

const AfiliadoLink = () => {
  return (
    <div className="limiterafiliados">
      <div className="sitecanva">
        <iframe
          loading="lazy"
          className="inframecanva"
          src="https:&#x2F;&#x2F;
www.canva.com
&#x2F;design&#x2F;DAFw0EIs0mc&#x2F;view?embed"
          allowfullscreen="allowfullscreen"
          allow="fullscreen"
        ></iframe>
      </div>

      <Footer />
    </div>
  );
};

export default AfiliadoLink;
