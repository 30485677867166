import React from "react";

import '../assets/css/RedeSociaisComponent.css';

const RedeSociaisComponent = (props) => {
    const {
        statusMode,
       
      } = props;
  const wpplogo = `${process.env.PUBLIC_URL}/images/icons8-whatsapp-240.png`;
  const instalogo = `${process.env.PUBLIC_URL}/images/icons8-instagram-240.png`;
  const youtlogo = `${process.env.PUBLIC_URL}/images/icons8-reproduzir-youtube-240.png`;
  return (
    <div className={` rede-sociais-page container text-light `}> {/* Use a classe de estilo CSS aqui */}
      <h1>Rede Sociais</h1>
      <ul className={'social-links'}>
       
          
          
            
        <li>
        <a
            href="https://wa.me/message/SKLE6BOPTNTYC1"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <img src={wpplogo} alt="WhatsApp" className="social-icos" /><h1>WhatsApp</h1>
          </a>
        </li>
        <li>
        <a
            href="https://www.instagram.com/arbitragem.bet/"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <div className="social-group">
              <img src={instalogo} alt="Instagram" className="social-icos" />
              <circle
                data-color="color-2"
                cx="49.082"
                cy="14.918"
                r="3.84"
                className="social-circle"
              ></circle>
              <h1>Instagram</h1>
            </div>
          </a>
        </li>
        <li>
        <a
            href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <img src={youtlogo} alt="YouTube" className="social-icos" /> <h1>YouTube</h1>
          </a>
        </li>
        {/* Adicione mais links conforme necessário */}
      </ul>
    </div>
  );
};

export default RedeSociaisComponent;
