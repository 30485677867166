import axios from "axios";
import { orderLoop } from "./GlobalVariables";

function urlPathAlongWithParam(url) {
    const urlObject = new URL(url);
    const pathWithParams = urlObject.pathname + urlObject.search;
    return pathWithParams;
}

function clonerEstrelaBetToKTO(url) {
    let cc = url.toLowerCase().split("event/")
    let quantityItemCC = cc.length;
    let lastIntcc = quantityItemCC - 1;
    let last_cc = cc[lastIntcc];
    let last_cc_fragmented = last_cc.split("/");
    let id_surebet = last_cc_fragmented[0];
    let kto_url = `https://www.kto.com/pt/esportes/?page=event&sportId=68&eventId=${id_surebet}`;


    return kto_url;
}

function decodeIdBolsaDeApostas(url){
  let urlToArray = url.split('/')
  let qtItems = urlToArray.length
  let lastItem = qtItems - 1;
  let idUrl =  lastItem !== -1 ? urlToArray[lastItem] : '';
  return idUrl
}

function clonerBetnacional(baseUrl, url) {
    const urlPathParam = urlPathAlongWithParam(url);
    const newURLBetHome = `${baseUrl}${urlPathParam}`;
    return newURLBetHome;
}


function clonerPixBet(baseUrl, url) {
    const urlPathParam = urlPathAlongWithParam(url);
    const newURLBetHome = `${baseUrl}${urlPathParam}`;
    return newURLBetHome;
}

function clonerVaiDeBet(baseUrl, url) {
  const urlPathParam = urlPathAlongWithParam(url);
  const newURLBetHome = `${baseUrl}${urlPathParam}`;
  return newURLBetHome;
}

function clonerSportingBet(baseUrl, url) {
    const urlPathParam = urlPathAlongWithParam(url);
    const newURLBetHome = `${baseUrl}${urlPathParam}`;
    return newURLBetHome;
}

function clonerBolsaDeAposta(baseUrl, url){
  const urlPathParam = decodeIdBolsaDeApostas(url);
  const newURLBetHome = `${baseUrl}${urlPathParam}`;
  return newURLBetHome;
}


function clonerPinnacles(baseUrl, url) {
  const urlPathParam = urlPathAlongWithParam(url);
  

  const newURLBetHome = `${baseUrl}${urlPathParam}`.replace('pt/', 'pt/standard/').replace('en/', 'pt/standard/');
  return newURLBetHome;
}


export function managerFunctionCloneBetHome(infoBetHome, baseBethomeClone, nameCloneBetHome, url) {

    const managerClonerBetHome = {
        'sportingbet': function () { return clonerSportingBet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'betnacional': function () { return clonerBetnacional(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'estrelabet': function () { return clonerEstrelaBetToKTO(url) },
        'pixbet': function () { return clonerPixBet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url).replace('esportes?', 'sports?') },
        'bolsadeaposta': function () { return clonerBolsaDeAposta(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'pinnacle': function () { return clonerPinnacles(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'vaidebet': function () { return clonerVaiDeBet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
    }

    let valueReturn = managerClonerBetHome[baseBethomeClone]();

    return valueReturn;
}

export async function statusUserCoupon(email) {

    
    
    const dataToSend = { 'e6245': email };
    const response = await fetch(`https://arbitragem.bet:1180/can_use_coupon`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

}


//---FILTER
export function updateClassifiactionSurebet(nameClassification, setStateClassification, nameLocalStorage){
    const nameClassificationLower = nameClassification.toLowerCase()
    setStateClassification( nameClassificationLower );
    localStorage.setItem(nameLocalStorage, JSON.stringify(nameClassificationLower));
}


//---LocalStorage

export function localStorageManagerReturn(nameLocalStorage, defaultValue){
    let itemLocalStorage = localStorage.getItem(nameLocalStorage);


    try {
        return  JSON.parse(itemLocalStorage);
    } catch {
        return defaultValue;
    }
}


export function organizatorSurebetByClassification(statusFilter, dataSurebet) {
    if (statusFilter === 'início') {
  
      let newDataSurebet = {};
      let keysObject = Object.keys(dataSurebet);
      let gcc = {};
      for (let i = 0; i < keysObject.length; i++) {
  
        let keyObject = keysObject[i];
        let valueObject = dataSurebet[keyObject]['main'];
        let listLargeTime = [];
  
        for (let i_orderLoop = 0; i_orderLoop < orderLoop.length; i_orderLoop++) {
          let order = orderLoop[i_orderLoop];
  
          if (order in valueObject) {
  
  
            listLargeTime.push(valueObject[order]['time_milliseconds']);
  
          }
        }
  
        let listLargeTimeOrdenated = listLargeTime.sort();
        listLargeTimeOrdenated.reverse()
  
        if (gcc[listLargeTimeOrdenated[0]] === undefined) {
          gcc[listLargeTimeOrdenated[0]] = [keyObject];
        } else {
          gcc[listLargeTimeOrdenated[0]].push(keyObject);
        }
      }
  
  
      let keysGcc = Object.keys(gcc);
      let keysGccOrdenated = keysGcc.sort();
  
      for (let i = 0; i < keysGccOrdenated.length; i++) {
        let keyGccOrdenated = keysGccOrdenated[i];
  
        for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
          let item = gcc[keyGccOrdenated][i_gccItem];
          newDataSurebet[item] = dataSurebet[item];
        }
      }
      
      //console.log(newDataSurebet);
      return (newDataSurebet);
    }
    else if (statusFilter === 'recentes') {
  
      let newDataSurebet = {};
      let keysObject = Object.keys(dataSurebet);
      let gcc = {};
      let keysNumber = [];
      for (let i = 0; i < keysObject.length; i++) {
  
        let keyObject = keysObject[i];
        let valueObject = dataSurebet[keyObject]['main'];
        let listLargeTime = [];
  
  
  
  
  
        let timeLower = valueObject['time'].toLowerCase();
  
        let g = timeLower.indexOf('h') != -1
          ? (parseFloat(timeLower.replace('h', '')) * 60)
          : timeLower.indexOf('dia') != -1 ?
            (parseFloat(timeLower.replace('dias', '').replace('dia', '')) * 1440)
  
            : parseFloat(timeLower.replace('min', ''));
        //console.log('timeLower', timeLower);
        //console.log('g', g);
  
        if (gcc[g] === undefined) {
          gcc[g] = [keyObject];
          keysNumber.push(g);
        } else {
          gcc[g].push(keyObject);
        }
      }
  
      //console.log(gcc);
      let keysGcc = Object.keys(gcc);
      let keysGccOrdenated = keysNumber.sort((a, b) => a - b);
      
      
      for (let i = 0; i < keysGccOrdenated.length; i++) {
        let keyGccOrdenated = keysGccOrdenated[i].toString();
  
        for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
          let item = gcc[keyGccOrdenated][i_gccItem];
          newDataSurebet[item] = dataSurebet[item];
        }
      }
  
      //console.log(keysGccOrdenated)
      //console.log('newDataSurebet', newDataSurebet);
      return (newDataSurebet);
    }
    else {
      return (dataSurebet);
    }
  }


export function decodeChance(chance_surebet) {
  let valueToReturn = {
    'chance': 0,
    'percent': 0,
  }

  let hasStart = chance_surebet.indexOf('(')
  let hasEnd = chance_surebet.indexOf(')')

  if (hasStart != -1 && hasStart != -1) {
    let chanceFragmented = chance_surebet.split('(');
    let realChance = Number(chanceFragmented[0])
    let percentChance = Number(chanceFragmented[1].replaceAll('(', '').replaceAll(')', '').replaceAll(',', '.').replaceAll('%', ''));

    valueToReturn['chance'] = realChance;
    valueToReturn['percent'] = percentChance;

    return valueToReturn;
  }
  valueToReturn['chance'] = Number(chance_surebet)
  return valueToReturn
}

export async function checkSessiondID(sessionIDs) {
  if (sessionIDs) {
    const concurrentLoginResponse = await axios.post(
      "https://arbitragem.bet:8081/api/check-concurrent-session",
      {
        userID: sessionIDs,
      }
    );
    const checkdata = await concurrentLoginResponse.data.concurrentLogin;
    if (!checkdata) {
      
      return false;
    }else{
      return true;
    }
  } else {
    return true;
  }
}