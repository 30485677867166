export function FilterHead(){
  return ({
      'readLocalstorage':()=>{
          
          const listPatternValues = {  
              login: false,               
              profitMin: -999,
              profitMax: 999,
              time: 'qualquer horario',
              listBehomeEnabled: [  "amuletobet",
              "bet365 (full)",
              "betano (br)",
              "betcris",
              "betfair sb",
              "betsson",
              "betwarrior",
              "betway",
              "bodog (en)",
              "betboo",
              "bwin",
              "sportingbet",
              "kto",
              "pinnacle",
              "pixbet",
              "playpix",
              "vbet (lat)",
              "estrelabet",
              "betnacional",
              "betsul",,],
              listSportsEnabled: [  "artes maciais",
              "badminton",
              "bandy",
              "basquete",
              "basquete 3x3",
              "basquete 4x4",
              "beisebal",
              "boliche",
              "dardos",
              "floorball",
              "futebol",
              "futebol 3x3",
              "futebol 4x4",
              "futebol 5x5",
              "futebol gaélico",
              "futebol americano",
              "futebol australiano",
              "futebol de praia",
              "futebol de salão",
              "golfe",
              "handebol",
              "handebol de praia",
              "hurling",
              "hóquei",
              "hóquei de ar",
              "hóquei de ar 2x2",
              "hóquei em campo",
              "lacrosse",
              "netbol",
              "o que onde quando",
              "pólo aquático",
              "rugby",
              "sinuca",
              "tênis",
              "tênis de mesa",
              "voleibol",
              "voleibol de praia",
              "xadrez",
              "arena of valor",
              "call of duty",
              "counter-strike",
              "dota",
              "king of glory",
              "league of legends",
              "nba2k",
              "overwatch",
              "rainbow",
              "rocket league",
              "starcraft",
              "valorant",
              "warcraft",],
              trash: [],
              trash_aovivo:[],
              qauntityMoney: 1000,
              roundedValue: 1,
              roundedIsActive: false,
              statusMode: false,
              userData:{
                  "sessionID": "",
                  "id": "",
                  "nome": "",
                  "email": "",
                  "senha": "",
                  "plano": "",
                  "tipo_de_plano": "",
                  "status_da_conta": "",
                  "username": "",
                  "phone": "",
                  "cuponuser": null,
                  "data_cadastro": "",
                  "data_pagamento": null,
                  "data_expiracao": null
              },
              Idinitialization:"",
              Amountinitialization:0,
              preferenceID:"",
              entradaslight:true,
              entradaspro:true,
              preferenceData:{},
              session_id:"",

          };
          const listKeys = Object.keys(listPatternValues);

          for(let indexListKeys = 0; indexListKeys < listKeys.length; indexListKeys++){
              let keyLocalStorage = listKeys[indexListKeys];
              
              if( localStorage.getItem(keyLocalStorage) !== null ){                    
                  continue;
              }                
              //let itemConvertedToJson = JSON.stringify( listPatternValues[keyLocalStorage] );

              localStorage.setItem(keyLocalStorage,  JSON.stringify( listPatternValues[keyLocalStorage] ));
          }
         
          
      },
      'updateLocalStorage': (key, data) =>{
          const dataToJson = JSON.stringify( data );
          localStorage.setItem(key, dataToJson);
      }
  });
}