
import "../assets/css/calculator.css";
import React, { useState, useEffect, useRef } from "react";
import { ArrowUp, ArrowDown } from "../global/ExternalIcon";
import { Tooltip } from 'react-tooltip';
import EmptyCalculatorMobile from './EmptyCalculatorMobile';
import { useMediaQuery } from 'react-responsive';

const orderloop = [
  "first_home_bet",
  "second_home_bet",
  "third_home_bet",
  "fourth_home_bet",
];


function EmptyCalculator(props) {
  const { statusMode, isLoggedIn } = props;
  const [data, setDataCalculator] = useState({});
  const [odds, setOdds] = useState([]);
  const [profitOdds, setProfitOdds] = useState([]);
  const [qtMoneyBet, setQtMoneyBet] = useState([]);
  const [qtMoney, setqtMoney] = useState(
    JSON.parse(localStorage["qauntityMoney"])
  );
  const [profit, setProfit] = useState(0);
  const [valueRounded, setValueRounded] = useState(
    JSON.parse(localStorage["roundedValue"])
  );
  const [statusRounded, setStatusRounded] = useState(
    JSON.parse(localStorage["roundedIsActive"])
  );
  const [CalcPay, setCalcPay] = useState(-999);
  const intervalRef = useRef(null);
  const [quantitySurebet, setQuantitySurebet] = useState(-1);
  const [infoCalcFrontEntJSON, setInfoCalcFrontEntJSON] = useState(JSON.stringify({ 'qtMoney': Number(qtMoney).toString().replace('.', ','), 'allStatusComma': {} }));
  const isMobile = useMediaQuery({ query: '(max-width: 790px)' });
  let infoCalcFrontEnd = JSON.parse(infoCalcFrontEntJSON);
  const eventMouseDownIncreaseValueCalculator = (value, functionUpdateState, valueToIncrement = 1) => {

    let valueToChange = Number(value);
    if (!intervalRef.current) {

      setTimeout(() => {
        valueToChange += valueToIncrement;
        functionUpdateState(valueToChange);
      }, 1);

      intervalRef.current = setInterval(() => {
        valueToChange += valueToIncrement;
        functionUpdateState(valueToChange);
      }, 200);
    }
  };

  const eventMouseDownDecreaseValueCalculator = (value, functionUpdateState, valueToDecrement = 1) => {
    let valueToChange = Number(value);
    if (!intervalRef.current) {
      setTimeout(() => {
        valueToChange -= valueToDecrement;
        functionUpdateState(valueToChange);
      }, 1);

      intervalRef.current = setInterval(() => {
        valueToChange -= valueToDecrement;
        functionUpdateState(valueToChange);
      }, 200);
    }
  };


  const handleMouseUpOrLeave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };


  function eventPressButtonUpDownIncreaseDecreaseValue(event, functionUpdateState, valueToIncrementDecrement = 1) {
    const { value } = event.target;


    let valueModified = Number(value.replaceAll(',', '.'));

    if (!isNaN(valueModified)) {
      if (event.key === 'ArrowUp') {
        let z = valueModified + valueToIncrementDecrement;
        functionUpdateState(z);
      }
      else if (event.key === 'ArrowDown') {
        let z = valueModified - valueToIncrementDecrement;
        functionUpdateState(z);
      }

    }
  }




  function addictionNumber(total, num) {
    return total + num;
  }

  function surebetCalculate(
    totalMoney,
    oddBetHome,
    allProfitBethome,
    roundedState,
    roundedValue,
    stakeSelected
  ) {
    let qtMoneyBetHome = [0, 0];
    oddBetHome = oddBetHome.map(function (str) {
      return Number(str);
    });

    let oddsTotalAddiction = oddBetHome.reduce(addictionNumber);

    for (let i = 0; i < oddBetHome.length; i++) {
      qtMoneyBetHome[i] =
        totalMoney - (totalMoney / oddsTotalAddiction) * oddBetHome[i];
      allProfitBethome[i] = qtMoneyBetHome[i] * oddBetHome[i] - totalMoney;
    }
    let additionProfit = 0;
    for (let i = 0; i < allProfitBethome.length; i++) {
      additionProfit += allProfitBethome[0];
    }

    const newProfit =
      (additionProfit / allProfitBethome.length / totalMoney) * 100;

    if (roundedState) {
      let newQtMoney = 0;
      for (let i = 0; i < oddBetHome.length; i++) {
        if (stakeSelected !== i) {
          qtMoneyBetHome[i] = Math.round(qtMoneyBetHome[i] / roundedValue) * roundedValue;
        }
        newQtMoney += qtMoneyBetHome[i];
      }

      for (let i = 0; i < oddBetHome.length; i++) {
        allProfitBethome[i] = qtMoneyBetHome[i] * oddBetHome[i] - newQtMoney;
      }
    }

    setProfitOdds(allProfitBethome);
    let totalCash = 0;
    let numberFormated = qtMoneyBetHome.map(function (num) {
      totalCash += num;
      return num.toFixed(2);
    });
    setQtMoneyBet(numberFormated);



    if (stakeSelected !== -999) {
      let roundedResult = Math.round(totalCash * 100) / 100;
      setqtMoney(roundedResult);
    };
    setProfit(newProfit);
  }

  function changeQtMoney(
    value,
    oddBetHome,
    allProfitBethome,
    roundedState,
    roundedValue,
    actualCalcPay,
  ) {
    //const { value } = event.target;
    const totalMoney = Number(value);

    localStorage.setItem("qauntityMoney", JSON.parse(totalMoney));

    setqtMoney(totalMoney);
    surebetCalculate(
      totalMoney,
      oddBetHome,
      allProfitBethome,
      roundedState,
      roundedValue,
      actualCalcPay,
    );
  }

  function changeOdds(
    value,
    indexOdd,
    totalMoney,
    oddBetHome,
    allProfitBethome,
    roundedState,
    roundedValue
  ) {


    let newOddBetHomeModified = oddBetHome;
    newOddBetHomeModified[indexOdd] = value;

    setOdds((prevArray) => {
      const newOddBetHomeyy = [...prevArray];
      newOddBetHomeyy[indexOdd] = value;
      return newOddBetHomeyy;
    });

    let listOddInNumber = newOddBetHomeModified.map(function (str) {
      return Number(str);
    });
    if (CalcPay === -999) {
      surebetCalculate(
        totalMoney,
        listOddInNumber,
        allProfitBethome,
        roundedState,
        roundedValue,
        CalcPay,
      );
    } else {
      adjusterValueSurebet(CalcPay, listOddInNumber);
    }
  }

  function changeBetValue(
    value,
    indexMoney,
    totalMoney,
    oddBetHome,
    allMoneyBethome
  ) {

    const valueConverted = Number(value);


    let newMoneyBethomeModified = allMoneyBethome.map(function (str) {
      return Number(str);
    });

    if (odds[CalcPay] !== undefined && CalcPay == indexMoney) {
      let totalCash = valueConverted;
      let oddBase = odds[CalcPay];

      for (let i = 0; i < quantitySurebet; i++) {

        if (i != CalcPay) {
          let only_odd = Number(odds[i]);

          let partCash = (valueConverted / only_odd) * oddBase;

          newMoneyBethomeModified[i] = partCash;
          totalCash += partCash;

        }
      }


      let roundedResultTotalCash = Math.round(totalCash * 100) / 100;

      changeQtMoney(
        roundedResultTotalCash,
        odds,
        profitOdds,
        statusRounded,
        valueRounded,
        CalcPay
      );
    }
    else {


      newMoneyBethomeModified[indexMoney] = valueConverted;

      let newValueTotalMoney = 0;
      for (let index = 0; index < newMoneyBethomeModified.length; index++) {
        newValueTotalMoney = newMoneyBethomeModified[index] + newValueTotalMoney;
      }
      let roundedResultTotalCash = Math.round(newValueTotalMoney * 100) / 100;
      setqtMoney(roundedResultTotalCash);

      for (let index = 0; index < newMoneyBethomeModified.length; index++) {
        let newProfitBetHomeModified =
          newMoneyBethomeModified[index] * oddBetHome[index] - newValueTotalMoney;

        setProfitOdds((prevArray) => {
          const newProfitBetHome = [...prevArray];
          newProfitBetHome[index] = newProfitBetHomeModified;
          return newProfitBetHome;
        });
      } //setProfitOdds

      setQtMoneyBet((prevArray) => {
        const newMoneyBetHome = [...prevArray];
        newMoneyBetHome[indexMoney] = value;
        return newMoneyBetHome;
      });
    }
  }

  const fetchData = (quantityMoney, roundedState, roundedValue) => {
    
    let dataMainSurebet = {
      profit: "0",
      first_home_bet: {
        name_bet_home: "+ 1",
        chance: "2",
      },
      second_home_bet: {
        name_bet_home: "+ 2",
        chance: "2",
      },
    };
    

    if (dataMainSurebet !== undefined) {
      setDataCalculator(dataMainSurebet);

      let chanceBetHome = [];
      let profitBethome = [];
      let qtMoneyInBetHome = [];

      for (let i = 0; i < orderloop.length; i++) {
        let order = orderloop[i];

        if (order in dataMainSurebet) {
          let odd = parseFloat(dataMainSurebet[order]["chance"].split("(")[0]);
          chanceBetHome[i] = odd;
          profitBethome[i] = 0;
          qtMoneyInBetHome[i] = 0;
        }
      }



      infoCalcFrontEnd['odds'] = chanceBetHome.map((item, index) => {
        infoCalcFrontEnd['allStatusComma']['odds'][index] = true;
        return item.toString().replace('.', ',');
      });

      infoCalcFrontEnd['qtMoneyBet'] = qtMoneyInBetHome.map((item, index) => {
        infoCalcFrontEnd['allStatusComma']['qtMoneyBet'][index] = true;
        return item.toString();
      });
      let infoCalcFrontEndConvertted = JSON.stringify(infoCalcFrontEnd);
      setInfoCalcFrontEntJSON(infoCalcFrontEndConvertted);

      setOdds(chanceBetHome.map(item => item.toFixed(3)));
      setProfitOdds(profitBethome);
      setQtMoneyBet(qtMoneyInBetHome);


      surebetCalculate(
        quantityMoney,
        chanceBetHome,
        profitBethome,
        roundedState,
        roundedValue,
        CalcPay
      );
    }
  };
  useEffect(() => {
    infoCalcFrontEnd['allStatusComma']['qtMoney'] = true;
    infoCalcFrontEnd['allStatusComma']['odds'] = [];
    infoCalcFrontEnd['allStatusComma']['qtMoneyBet'] = [];
    let infoCalcFrontEndConvertted = JSON.stringify(infoCalcFrontEnd);
    setInfoCalcFrontEntJSON(infoCalcFrontEndConvertted);
    setQuantitySurebet(2);
    fetchData(qtMoney, statusRounded, valueRounded);

      if (statusMode) {
        
        document.documentElement.className = "dark-theme";
        document.documentElement.setAttribute('data-bs-theme', 'dark-theme');
      } else {
        document.documentElement.className = "light-theme";
        document.documentElement.setAttribute('data-bs-theme', 'light-theme');
      }
      localStorage.setItem("theme", statusMode);
  }, []);


  useEffect(() => {

    let oldQtMoney = Number(infoCalcFrontEnd['qtMoney'].replace(',', '.'));
    let actualQtMoney = Number(qtMoney)

    if (oldQtMoney !== actualQtMoney) {
      if (infoCalcFrontEnd['allStatusComma']['qtMoney']) {
        infoCalcFrontEnd['qtMoney'] = actualQtMoney.toString().replace('.', ',');

      } else {
        infoCalcFrontEnd['qtMoney'] = actualQtMoney.toString();

      }
    }

    for (let i = 0; i < qtMoneyBet.length; i++) {
      let actualQtMoneyBetHome = Number(qtMoneyBet[i]);
      let oldQTMoneyBet = Number(infoCalcFrontEnd['qtMoneyBet'][i].replace(',', '.'));


      if (actualQtMoneyBetHome !== oldQTMoneyBet) {
        
        if (infoCalcFrontEnd['allStatusComma']['qtMoneyBet'][i]) {
          infoCalcFrontEnd['qtMoneyBet'][i] = actualQtMoneyBetHome.toString().replace('.', ',');

        } else {
          infoCalcFrontEnd['qtMoneyBet'][i] = actualQtMoneyBetHome.toString();

        }

      }
    }


    let infoCalcFrontEndConvertted = JSON.stringify(infoCalcFrontEnd);

    setInfoCalcFrontEntJSON(infoCalcFrontEndConvertted);

  }, [qtMoney, qtMoneyBet]);

  function getCashFixed(indexOdd, oddsToAjusterValue = odds) {

    if (oddsToAjusterValue[indexOdd] !== undefined) {
      let newMoneyBethomeModified = qtMoneyBet;
      let valueConverted = Number(qtMoneyBet[indexOdd]);
      let totalCash = valueConverted;
      let oddBase = oddsToAjusterValue[indexOdd];
      for (let i = 0; i < quantitySurebet; i++) {
        if (i != indexOdd) {
          let only_odd = Number(oddsToAjusterValue[i]);

          let partCash = (valueConverted / only_odd) * oddBase;
          newMoneyBethomeModified[i] = partCash;
          totalCash += partCash;

        }
      }

      let roundedResultTotalCash = Math.round(totalCash * 100) / 100;
      return roundedResultTotalCash;
    }
    else {
      return qtMoney;
    }
  }


  function adjusterValueSurebet(indexOdd, oddsToAjusterValue = odds) {
    setCalcPay(indexOdd)
    let roundedResultTotalCash = getCashFixed(indexOdd, oddsToAjusterValue);


    changeQtMoney(
      roundedResultTotalCash,
      oddsToAjusterValue,
      profitOdds,
      statusRounded,
      valueRounded,
      indexOdd
    );

  }

  return (
    <>
    {!isMobile
    ?
    <div
      className={`card m-3 shadow ${statusMode ? "bgDark borderColor textGray shadowDark" : ""
        }`}
      style={{ marginTop: "70px" }}
    >
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="card-title m-0">Calculadora de arbitragens</h5>
            <h6 className="mb-3">Calcule suas apostas aqui</h6>
          </div>
          <p
            id="profit_game"
            className={`fonte-lucro ${profit >= 0 ? "text-success" : "text-danger"
              } defaultFontSizeCalculator`}
            style={{ color: "black" }}
          >
            {profit >= 0 ? "+" : ""}
            {profit.toFixed(2)}%
          </p>
        </div>

        <div className="row px-1 py-3">
          <div className="col-9"></div>
        </div>

        <div
          className={`text-center row border-bottom ${statusMode ? "borderColor" : ""
            }`}
        >
          <div className="col-4 fw-bold">Mercado</div>
          <div className="col-2 fw-bold">Chance</div>
          <div className="col fw-bold">Aposta</div>
          <div className="col fw-bold">Lucro</div>
        </div>
        {
          // eslint-disable-next-line
          orderloop.map((order, index) => {
            if (order in data) {
              return (
                <div
                  className={`row border-bottom d-flex align-items-center ${statusMode ? "borderColor" : ""
                    }`}
                  key={index}
                >
                  <div className="col-4">
                    <div
                      className="text-decoration-none text-center"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <p
                        className={`p-calculator d-inline px-4 text-decoration-none ${statusMode ? "textGray" : "text-black"
                          }`}
                      >
                        {data[order]["name_bet_home"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-2 d-flex justify-content-center align-items-center fs-5">
                    <input
                      type="text"
                      className={`odds align-self-center form-control my-1 ${statusMode ? "borderColor textGray bgDark" : ""
                        } defaultFontSizeCalculator inputCalculator`}
                      placeholder="0.00"
                      style={{ width: "70px" }}
                      value={infoCalcFrontEnd['odds'][index]}


                      onChange={(event) => {
                        const { value } = event.target;

                        let valueModified = Number(value.replaceAll(',', '.'));

                        if (!isNaN(valueModified)) {

                          infoCalcFrontEnd['odds'][index] = value;
                          infoCalcFrontEnd['allStatusComma']['odds'][index] = value.includes(',');
                          setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));
                          changeOdds(
                            valueModified,
                            index,
                            qtMoney,
                            odds,
                            profitOdds,
                            statusRounded,
                            valueRounded
                          );
                        }
                      }}
                      onKeyDown={(event) => {



                        let paramFunction = function (paramState) {
                          infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                          setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                          changeOdds(
                            paramState,
                            index,
                            qtMoney,
                            odds,
                            profitOdds,
                            statusRounded,
                            valueRounded
                          );
                        }

                        eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction, 0.01);
                      }
                      }

                    />
                    <div className="d-flex flex-column">
                      <button className="btn p-0 m-0 btn-arrow"
                        onMouseDown={() => {
                          let paramFunction = function (paramState) {
                            infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                            setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));


                            changeOdds(
                              paramState,
                              index,
                              qtMoney,
                              odds,
                              profitOdds,
                              statusRounded,
                              valueRounded
                            );
                          }
                          eventMouseDownIncreaseValueCalculator(Number(odds[index]).toFixed(2), paramFunction, 0.01);
                        }}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                      >
                        <ArrowUp />
                      </button>
                      <button className="btn p-0 m-0 btn-arrow"
                        onMouseDown={() => {
                          let paramFunction = function (paramState) {
                            infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                            setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));
                            changeOdds(
                              paramState,
                              index,
                              qtMoney,
                              odds,
                              profitOdds,
                              statusRounded,
                              valueRounded
                            );
                          }
                          eventMouseDownDecreaseValueCalculator(Number(odds[index]).toFixed(2), paramFunction, 0.01);
                        }}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                      >
                        <ArrowDown />
                      </button>
                    </div>
                  </div>

                  <div className="col d-flex justify-content-center fs-5 d-flex justify-content-center align-items-center">
                    <input
                      type="text"
                      className={`qt_money align-self-center form-control my-1 ${statusMode ? "borderColor textGray bgDark" : ""
                        } defaultFontSizeCalculator inputCalculator`}
                      placeholder="R$ 0,00"
                      style={{
                        minWidth: "50px",
                        width: `${(qtMoney && qtMoney.toString().length * 10) + 50
                          }px`,
                        maxWidth: '140px',
                        padding: "8px",
                        textAlign: "right",
                        resize: "both",
                      }}

                      value={infoCalcFrontEnd['qtMoneyBet'][index]}

                      onChange={(event) => {
                        const { value } = event.target;

                        let valueModified = Number(value.replaceAll(',', '.'));

                        if (!isNaN(valueModified)) {
                          infoCalcFrontEnd['qtMoneyBet'][index] = value;
                          infoCalcFrontEnd['allStatusComma']['qtMoneyBet'][index] = value.includes(',');
                          setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                          changeBetValue(
                            valueModified,
                            index,
                            qtMoney,
                            odds,
                            qtMoneyBet
                          );
                        }


                      }
                      }
                      onKeyDown={(event) => {



                        let paramFunction = function (paramState) {
                          changeBetValue(
                            parseInt(paramState),
                            index,
                            qtMoney,
                            odds,
                            qtMoneyBet
                          );
                        }

                        eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction);
                      }
                      }

                    />

                    <div className="d-flex flex-column align-items-center">
                      <button className="btn p-0 m-0 btn-arrow"
                        onMouseDown={() => {
                          let paramFunction = function (paramState) {
                            changeBetValue(
                              parseInt(paramState),
                              index,
                              qtMoney,
                              odds,
                              qtMoneyBet
                            );
                          }
                          eventMouseDownIncreaseValueCalculator(qtMoneyBet[index], paramFunction);
                        }}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                      >
                        <ArrowUp />
                      </button>
                      <button className="btn p-0 m-0 btn-arrow"
                        onMouseDown={() => {
                          let paramFunction = function (paramState) {

                            changeBetValue(
                              parseInt(paramState),
                              index,
                              qtMoney,
                              odds,
                              qtMoneyBet
                            );
                          }
                          eventMouseDownDecreaseValueCalculator(qtMoneyBet[index], paramFunction);
                        }}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                      >
                        <ArrowDown />
                      </button>
                    </div>

                    <div className="form-check ms-3 d-flex align-items-center">
                      <input className="form-check-input d-block" type="radio" name="flexRadioDefault" id={`checker_${order}`} onChange={() => { adjusterValueSurebet(index) }} checked={CalcPay === index ? true : false} />
                    </div>
                  </div>

                  <div className="p-calculator profit col d-flex justify-content-center fs-5">
                    <p className="m-0"data-tooltip-id={`tooltip_${order}`}
                          data-tooltip-content={profitOdds[index].toFixed(2)}
                        >



                          {profitOdds[index].toFixed(2).length > 9 ? `${profitOdds[index].toFixed(2).slice(0, 9)}...` : profitOdds[index].toFixed(2)}
                          <Tooltip id={`tooltip_${order}`} />
                        </p>
                  </div>
                </div>
              );
            }
          })
        }

        <div className="row">

          <div className="col-6"></div>

          <div className="col">
            <div className="d-flex justify-content-center align-items-center fs-5">
              <input

                id="total_value"
                type="text"
                style={{
                  minWidth: "16vw!important",
                  width: `${(qtMoney && qtMoney.toString().length * 10) + 50}px`,
                  maxWidth: '140px',
                  padding: "5px",
                  textAlign: "right",
                  minWidth: "50px",

                }}
                className={`qt_money align-self-center form-control my-1 ${statusMode ? "borderColor textGray bgDark" : ""
                  } defaultFontSizeCalculator inputCalculator `}
                placeholder="R$ 0.00"
                value={infoCalcFrontEnd['qtMoney']}
                onChange={(event) => {

                  const { value } = event.target;

                  let valueModified = Number(value.replaceAll(',', '.'));

                  if (!isNaN(valueModified)) {
                    infoCalcFrontEnd['qtMoney'] = value;
                    infoCalcFrontEnd['allStatusComma']['qtMoney'] = value.includes(',');

                    setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                    changeQtMoney(
                      valueModified,
                      odds,
                      profitOdds,
                      statusRounded,
                      valueRounded,
                      -999,
                    );
                  }

                }}
                onKeyDown={(event) => {



                  let paramFunction = function (paramState) {
                    changeQtMoney(
                      parseInt(paramState),
                      odds,
                      profitOdds,
                      statusRounded,
                      valueRounded,
                      -999,
                    );
                  }

                  eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction);
                }
                }
              />

              <div className="d-flex flex-column">
                <button className="btn p-0 m-0 btn-arrow"
                  onMouseDown={() => {
                    let paramFunction = function (paramState) {
                      changeQtMoney(
                        parseInt(paramState),
                        odds,
                        profitOdds,
                        statusRounded,
                        valueRounded,
                        -999,
                      );
                    }
                    eventMouseDownIncreaseValueCalculator(qtMoney, paramFunction);
                  }}
                  onMouseUp={handleMouseUpOrLeave}
                  onMouseLeave={handleMouseUpOrLeave}
                >
                  <ArrowUp />
                </button>
                <button className="btn p-0 m-0 btn-arrow"
                  onMouseDown={() => {
                    let paramFunction = function (paramState) {
                      changeQtMoney(
                        parseInt(paramState),
                        odds,
                        profitOdds,
                        statusRounded,
                        valueRounded,
                        -999,
                      );
                    }
                    eventMouseDownDecreaseValueCalculator(qtMoney, paramFunction);
                  }}
                  onMouseUp={handleMouseUpOrLeave}
                  onMouseLeave={handleMouseUpOrLeave}
                >
                  <ArrowDown />
                </button>
              </div>
              <div className="form-check ms-3 d-flex align-items-center">
                <input className="form-check-input d-block" type="radio" name="flexRadioDefault" id="checker_total" checked={CalcPay === -999 ? true : false} onChange={() => { setCalcPay(-999) }} />
              </div>
            </div>
          </div>
          <div className="col">


          </div>
        </div>

        <div
          className={`card shadow-sm p-2 ${statusMode ? "borderColor textGray " : "bg_gray"
            }`}
          style={{ backgroundColor: statusMode ? "rgb(25, 28, 31)" : "" }}
        >
          <div
            className="form-check d-flex justify-content-start align-items-center"
            style={{ fontSize: "small" }}
          >
            <input
              className="form-check-input me-1"
              type="checkbox"
              id="flexCheckDefault"
              style={{ minHeight: "15px", minWidth: "15px" }}
              checked={statusRounded}
              onChange={() => {
                setStatusRounded(!statusRounded);
                surebetCalculate(
                  getCashFixed(CalcPay),
                  odds,
                  profitOdds,
                  !statusRounded,
                  valueRounded,
                  CalcPay,
                );
                localStorage.setItem(
                  "roundedIsActive",
                  JSON.stringify(!statusRounded)
                );
              }}
            />
            <label className="form-check-label me-2" htmlFor="flexCheckDefault">
              Arredondar aposta até:
            </label>
            <div className="input-group" style={{ width: "120px" }}>
              <input
                className={`form-control h-25 ${statusMode ? "borderColor textGray bgDark" : ""
                  }`}
                type="text"
                placeholder="0.00"
                value={valueRounded}
                onChange={(event) => {
                  const { value } = event.target;
                  const valueModified = value.replace(/[^0-9.]/g, "");
                  setValueRounded(valueModified);
                  surebetCalculate(
                    getCashFixed(CalcPay),
                    odds,
                    profitOdds,
                    statusRounded,
                    Number(valueModified),
                    CalcPay,
                  );

                  localStorage.setItem(
                    "roundedValue",
                    JSON.stringify(Number(valueModified))
                  );
                }}
                disabled={!statusRounded}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    : 

<EmptyCalculatorMobile
statusMode={statusMode}
data={data}
profit={profit}
infoCalcFrontEnd={infoCalcFrontEnd}
setInfoCalcFrontEntJSON={setInfoCalcFrontEntJSON}
changeOdds={changeOdds}
qtMoney={qtMoney}
odds={odds}
profitOdds={profitOdds}
statusRounded={statusRounded}
valueRounded={valueRounded}
eventMouseDownDecreaseValueCalculator={eventMouseDownDecreaseValueCalculator}
handleMouseUpOrLeave={handleMouseUpOrLeave}
eventMouseDownIncreaseValueCalculator={eventMouseDownIncreaseValueCalculator}
changeBetValue={changeBetValue}
qtMoneyBet={qtMoneyBet}
adjusterValueSurebet={adjusterValueSurebet}
CalcPay={CalcPay}
setCalcPay={setCalcPay}
changeQtMoney={changeQtMoney}
setStatusRounded={setStatusRounded}
surebetCalculate={surebetCalculate}
getCashFixed={getCashFixed}
setValueRounded={setValueRounded}
/>
}
     </>
  );
}

export default EmptyCalculator;
