import DataGame from '../pages/DataGame';
import DataGameMobile from '../pages/DataGameMobile';
import { useMediaQuery } from 'react-responsive';

function ManagerDataGameExtension(props) {
    const {
        dataArb
    } = props;


    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

    return (
        <>

            {
                !isMobile ?
                    <DataGame
                        dataArb={dataArb}
                    />
                    :
                    <DataGameMobile
                        dataArb={dataArb}
                    />
            }
        </>
    );
}

export default ManagerDataGameExtension;