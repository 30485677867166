


function PaymentError() {
    return(<>
        <style>
            {`


                .container-page-payment-error{
                    width: 100vw;
                    min-height: 100vh;
                    background-color: #2a960d;

                    display: flex;
                    justify-content: center;
                    
                }


                .container-message{
                    margin-top: 100px;
                    margin-bottom: 50px;
                    background: linear-gradient(to  right bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7), #ffffff );
                    font-size: 25px;    
                    font-weight: bold;    
                    box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
                    backdrop-filter: blur(3px);    
                    width: 1440px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: fit-content;
                    padding-top: 50px;
                    padding-bottom: 50px;
                }


                .img-logo-payment-error{
                    width: 1008px;
                }

                @media (max-width: 1440px){
                    .container-message{
                        width: 98%;
                    }
                }


                @media (max-width: 1020px){
                    .container-message{
                        margin-top: calc((50/1020) * 100vw)!important;
                        margin-bottom: calc((50/1020) * 100vw)!important;
                    }

                    .img-logo-payment-error{
                        width: calc((1008/1020) * 100vw)!important;
                    }   
                }


                @media (max-width: 800px){
                    .container-message{
                        font-size: calc((25/800) * 100vw)!important;
                        margin-top: calc((20/800) * 100vw)!important;
                        margin-bottom: calc((20/800) * 100vw)!important;
                        padding-top: calc((50/800) * 100vw)!important;
                        padding-bottom: calc((50/800) * 100vw)!important;

                    }

                    .title-page-payment-error{
                        font-size: calc((34/800) * 100vw)!important;
                    }   

                    .icon-emoji-sad{
                        width: calc((150/800) * 100vw)!important;
                    }

                    .container-emoji-payment-error, .container-content-payment-error{
                        margin-top: calc((48/800) * 100vw)!important;
                    }
                }

                @media (max-width: 600px){
                    .container-message{
                    margin-top: 20%!important;
                    margin-bottom: 0!important;
                    }
                }

                @media (max-width: 450px){
                    .container-message{
                    margin-top: 50%!important;
                    }
                }
            `}
        </style>
        <div className="container-page-payment-error">
            <div className="container-message">
                <img className="img-logo-payment-error" src="./LOGOBRANCO.png" alt />
                <div className="mt-5 d-flex justify-content-center container-emoji-payment-error">
                    <div className="icon-emoji-sad" style={{ width: 100 }}>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                            <circle style={{ fill: '#ffe17d' }} cx={256} cy={256} r={256} />
                            <path style={{ fill: '#7d5046' }} d="M169.29,256L169.29,256c-11.402,0-20.645-9.243-20.645-20.645v-16.516 c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v16.516C189.935,246.757,180.692,256,169.29,256z" />
                            <path style={{ fill: '#9c6846' }} d="M169.29,198.194c-1.414,0-2.794,0.145-4.129,0.416v28.487c0,6.841,5.546,12.387,12.387,12.387 s12.387-5.546,12.387-12.387v-8.258C189.935,207.436,180.693,198.194,169.29,198.194z" />
                            <path style={{ fill: '#7d5046' }} d="M342.71,256L342.71,256c-11.402,0-20.645-9.243-20.645-20.645v-16.516 c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v16.516C363.355,246.757,354.112,256,342.71,256z" />
                            <path style={{ fill: '#9c6846' }} d="M342.71,198.194c-1.414,0-2.794,0.145-4.129,0.416v28.487c0,6.841,5.546,12.387,12.387,12.387 s12.387-5.546,12.387-12.387v-8.258C363.355,207.436,354.112,198.194,342.71,198.194z" />
                            <path style={{ fill: '#aa7346' }} d="M173.387,381.935c-2.5,0-5.008-0.903-6.992-2.726c-4.194-3.863-4.468-10.391-0.605-14.589 c20.952-22.77,54.669-36.363,90.177-36.363c35.524,0,69.234,13.597,90.177,36.367c3.863,4.194,3.589,10.726-0.605,14.585 c-4.185,3.863-10.726,3.601-14.589-0.609c-17.105-18.597-45.137-29.698-74.984-29.698c-29.839,0-57.871,11.101-74.984,29.702 C178.952,380.815,176.169,381.935,173.387,381.935z" />
                            <path style={{ fill: '#ffd164' }} d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463 C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538 C410.573,453.317,354.389,474.839,293.161,474.839z" />
                        </svg>
                    </div>
                </div>
                <div className="mt-5 text-center d-flex flex-column align-items-center container-content-payment-error">
                    <h2 className="fs-1 fw-bold title-page-payment-error">
                        Por favor, escolha um plano para continuar.
                    </h2>
                    <p className="w-75 content-page-payment-error">Notamos que você não selecionou um plano do Arbitragem.bet para efetuar o pagamento. Para aproveitar todos os benefícios oferecidos, é necessário escolher um dos nossos planos disponíveis. Por favor, retorne à página de seleção de planos, escolha o que melhor atende às suas necessidades e prossiga com o pagamento.</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default PaymentError;