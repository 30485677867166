import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import "../assets/css/HomePage.css";
import Footer from "./footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselHP from "./CarouselHP";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
const astropay = `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`;
const pinnacle = `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`;

const bet188 = `${process.env.PUBLIC_URL}/iconsparceiros/188BET_logo.png`;
const Amuletobet = `${process.env.PUBLIC_URL}/iconsparceiros/Amuletobet_logo_portrait.png`;
const bet365 = `${process.env.PUBLIC_URL}/iconsparceiros/bet365 contorno.png`;
const betano = `${process.env.PUBLIC_URL}/iconsparceiros/betano-logo-transp.png`;
const Betboo = `${process.env.PUBLIC_URL}/iconsparceiros/Betboo-br.png`;
const Betcris = `${process.env.PUBLIC_URL}/iconsparceiros/Betcris.png`;
const betfair = `${process.env.PUBLIC_URL}/iconsparceiros/betfair-logo-1.png`;
const betsson = `${process.env.PUBLIC_URL}/iconsparceiros/betsson_logo_large_transparent.png`;
const betWarrior = `${process.env.PUBLIC_URL}/iconsparceiros/betWarrior-logo.png`;
const bodog = `${process.env.PUBLIC_URL}/iconsparceiros/bodog.png`;
const Bwin = `${process.env.PUBLIC_URL}/iconsparceiros/Bwin-Logo-Vector.svg-.png`;
//const DESAFIO = `${process.env.PUBLIC_URL}/iconsparceiros/Copia_de_DESAFIO-removebg-preview.png`;
const KTO = `${process.env.PUBLIC_URL}/iconsparceiros/KTOlogo.png`;
const sportingbet = `${process.env.PUBLIC_URL}/iconsparceiros/sportingbet-logo.png`;
const estrelabet = `${process.env.PUBLIC_URL}/iconsparceiros/Estrela_Bet_logo.png`;
const betsul = `${process.env.PUBLIC_URL}/iconsparceiros/Betsul-Logo.png`;
const betnacional = `${process.env.PUBLIC_URL}/iconsparceiros/betnacional_logo.png`;

const iconbranca = `${process.env.PUBLIC_URL}/logo192.png`;
const logobranca = `${process.env.PUBLIC_URL}/LOGOBRANCO.png`;

// Defina um array com as URLs das imagens
const partnerImages = [
  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  `${process.env.PUBLIC_URL}/iconsparceiros/astropay.png`,
  `${process.env.PUBLIC_URL}/iconsparceiros/pinnacle.png`,

  // Adicione mais URLs conforme necessário
];
const linkparcerios = [
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
  "https://bit.ly/arbastropay",
  "https://bit.ly/arbpinnacle",
];

const sportsbookImages = [
  
  Amuletobet,
  bet365,
  betano,
  Betboo,
  Betcris,
  betfair,
  betsson,
  betWarrior,
  bodog,
  Bwin,
  KTO,
  sportingbet,
  estrelabet,
  betnacional,
  betsul,
  
  Amuletobet,
  bet365,
  betano,
  Betboo,
  Betcris,
  betfair,
  betsson,
  betWarrior,
  bodog,
  Bwin,
  KTO,
  sportingbet,
  estrelabet,
  betnacional,
  betsul,
];
const backgroundlogin = `${process.env.PUBLIC_URL}/images/bg home.png`;


const HomePage = () => {
  // Configurações do vídeo do YouTube
  const videoOpts = {
    playerVars: {
      autoplay: 0,
    },
  };
  const imghomepage = `${process.env.PUBLIC_URL}/images/PageInicial.jpg`;

  // State para armazenar as imagens dos parceiros e casas de apostas
  const [partnerImagesList, setPartnerImagesList] = useState([]);
  const [sportsbookImagesList, setSportsbookImagesList] = useState([]);

  // Função para adicionar imagens periodicamente com base no tamanho da página
  const addImagesPeriodically = (imageList, setImageList, interval) => {
    let index = 0;

    const addImage = () => {
      if (index < imageList.length) {
        setImageList((prevList) => [...prevList, imageList[index]]);
        index++;
      } else {
        clearInterval(intervalId); // Para o intervalo quando todas as imagens forem adicionadas
      }
    };

    const intervalId = setInterval(addImage, 500); // Adiciona uma imagem a cada meio segundo (ajuste conforme necessário)

    // Verifica o tamanho da página em tempo real
    window.addEventListener("resize", () => {
      const currentScreenWidth = window.innerWidth;

      // Você pode ajustar esses valores conforme necessário com base no tamanho da sua página
      if (currentScreenWidth <= 768) {
        // Por exemplo, adicione mais imagens quando a largura da tela for menor ou igual a 768px
        for (let i = 0; i < 100; i++) {
          addImage();
        }
      }
    });
  };

  useEffect(() => {
    addImagesPeriodically(partnerImages, setPartnerImagesList);
    addImagesPeriodically(sportsbookImages, setSportsbookImagesList);
  }, []);

  const [partnerCarouselDirection, setPartnerCarouselDirection] = useState(
    "right" // Direção padrão para a direita
  );

  // Função para mudar a direção do Carousel de parceiros
  const togglePartnerCarouselDirection = () => {
    setPartnerCarouselDirection((prevDirection) =>
      prevDirection === "right" ? "left" : "right"
    );
  };

  // State para controlar a direção do Carousel de casas de apostas
  const [sportsbookCarouselDirection, setSportsbookCarouselDirection] =
    useState("right"); // Direção padrão para a direita

  // Função para mudar a direção do Carousel de casas de apostas
  const toggleSportsbookCarouselDirection = () => {
    setSportsbookCarouselDirection((prevDirection) =>
      prevDirection === "right" ? "left" : "right"
    );
  };

  // Configurações do Carousel de parceiros
  const partnerCarouselSettings = {
    // ... outras configurações do Carousel de parceiros
    customTransition: `transform 500ms ${partnerCarouselDirection}`,
  };

  // Configurações do Carousel de casas de apostas
  const sportsbookCarouselSettings = {
    // ... outras configurações do Carousel de casas de apostas
    customTransition: `transform 500ms ${sportsbookCarouselDirection}`,
  };

  useEffect(() => {
    // Mudar a direção do Carousel de parceiros a cada 5 segundos (5000ms)
    const partnerIntervalId = setInterval(togglePartnerCarouselDirection, 5000);

    // Mudar a direção do Carousel de casas de apostas a cada 7 segundos (7000ms)
    const sportsbookIntervalId = setInterval(
      toggleSportsbookCarouselDirection,
      7000
    );

    return () => {
      clearInterval(partnerIntervalId); // Limpar o intervalo do Carousel de parceiros quando o componente for desmontado
      clearInterval(sportsbookIntervalId); // Limpar o intervalo do Carousel de casas de apostas quando o componente for desmontado
    };
  }, []);

  return (
    <div className="home-page">
      <div className="banner">
        <div className="content">

        <CarouselHP/>
        </div>
      </div>
      <div className="banner">
        <div className="video-section">
        <YouTube
              videoId="CgHLjngJoOw" // Substitua pelo ID do seu vídeo do YouTube
              opts={videoOpts}
            />
          
          <div className="video-text custom-container">
            <h2>
              <b>APRENDA SOBRE ARBITRAGEM!</b>
            </h2>
            <div className="cont80">
              <p className="pcustomhomepage">
                Você será muito bem vindo em nosso canal do YouTube. Lá você
                aprenderá tudo sobre Arbitragem, como funciona o nosso site e
                como você pode
                <b> ganhar dinheiro com apostas esportivas</b>. Tudo isso de
                forma gratuita, acesse já!.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="containerparceiros">
        <h1 className="titlehp">CONHEÇA NOSSOS PARCEIROS</h1>

        {/* Carrousel da cadas de Parcerios*/}
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={1000}
          centerMode={true}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={true}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={true}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
          {...partnerCarouselSettings}
        >
          {partnerImages.map((image, index) => (
             <a href={linkparcerios[index]} target="_blank" rel="noopener noreferrer">
            <div key={index} className="carousel-container-img2" >
              <img  src={image} alt={`Parceiro ${index + 1}`} />
            </div>
            </a>
          ))}
        </Carousel>
        {/* Carrousel da cadas de Apostas*/}

        {/* Carrousel da cadas de Parcerios*/}
        <div className="my-own-custom-container">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={1000}
            centerMode={true}
            className=""
            containerClass="carousel-container"
            dotListClass=""
            draggable
            focusOnSelect={true}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={true}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {sportsbookImages.map((image, index) => (
              <div className="carousel-container-img">
                <img key={index} src={image} alt={`Parceiro ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
