import React, { useState, useEffect} from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ExtensionBrowser from './params-urls-manager/ExtensionBrowser';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import MainContainerShowExtension from "./components/MainContainerShowExtension";

const root = ReactDOM.createRoot(document.getElementById('root'));


const RootComponent = () => {
  const location = useLocation();
  const [showComponent, setShowComponent] = useState(true);

  useEffect(() => {
    
    if (location.pathname === '/nav/arb/prong/0/abcdefg/if' ) {
      setShowComponent(true);
    } else {
      setShowComponent(false);
    }
  }, [location.pathname]);

  return (
    <>  
    <Routes>
      <Route path="/nav/arb/prong/0/abcdefg/if" element={
        <ExtensionBrowser/>
      } />
    </Routes>

    

    { showComponent ?
    ""
    :
    <App />
    }
  </>
  );
};


root.render(
  <BrowserRouter>
    <RootComponent/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
