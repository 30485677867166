



export function ArrowUp() {
    return (
        <div className="w-100 h-100 d-flex justify-conetent-center align-items-center">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.77 28.77" style={{ enableBackground: 'new 0 0 28.77 28.77' }} xmlSpace="preserve" fill="#505050">
                <g>
                    <g id="c105_arrow">
                        <path d="M28.679,22.971l-13.967-17.7c-0.16-0.201-0.494-0.201-0.656,0L0.09,22.971c-0.099,0.13-0.118,0.3-0.05,0.447 c0.069,0.143,0.218,0.232,0.376,0.232H28.35c0.162,0,0.308-0.09,0.376-0.232C28.8,23.27,28.777,23.1,28.679,22.971z" />
                    </g>
                    <g id="Capa_1_169_" />
                </g>
            </svg>

        </div>
    )
}


export function ArrowDown() {
    return (
        <div className="w-100 h-100  d-flex justify-conetent-center align-items-center" style={{ rotate: '180deg' }}>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.77 28.77" style={{ enableBackground: 'new 0 0 28.77 28.77' }} xmlSpace="preserve" fill="#505050">
                <g>
                    <g id="c105_arrow">
                        <path d="M28.679,22.971l-13.967-17.7c-0.16-0.201-0.494-0.201-0.656,0L0.09,22.971c-0.099,0.13-0.118,0.3-0.05,0.447 c0.069,0.143,0.218,0.232,0.376,0.232H28.35c0.162,0,0.308-0.09,0.376-0.232C28.8,23.27,28.777,23.1,28.679,22.971z" />
                    </g>
                    <g id="Capa_1_169_" />
                </g>
            </svg>
        </div>
    )
}


export function IconYoutube() {
    return (
        <div className="w-100 h-100 d-flex justify-conetent-center align-items-center">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                <path style={{}} d="M506.703,145.655c0,0-5.297-37.959-20.303-54.731c-19.421-22.069-41.49-22.069-51.2-22.952 C363.697,62.676,256,61.793,256,61.793l0,0c0,0-107.697,0.883-179.2,6.179c-9.71,0.883-31.779,1.766-51.2,22.952 C9.71,107.697,5.297,145.655,5.297,145.655S0,190.676,0,235.697v41.49c0,45.021,5.297,89.159,5.297,89.159 s5.297,37.959,20.303,54.731c19.421,22.069,45.021,21.186,56.497,23.835C122.703,449.324,256,450.207,256,450.207 s107.697,0,179.2-6.179c9.71-0.883,31.779-1.766,51.2-22.952c15.007-16.772,20.303-54.731,20.303-54.731S512,321.324,512,277.186 v-41.49C512,190.676,506.703,145.655,506.703,145.655" fill="#FFF" />
                <polygon style={{ fill: '#2a960d' }} points="194.207,166.841 194.207,358.4 361.931,264.828 " fill="#FFFFFF" />
            </svg>
        </div>

    )
}

export function IconPlus() {
    return (
        <div className="icon-plus">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 286.054 286.054" style={{ enableBackground: 'new 0 0 286.054 286.054' }} xmlSpace="preserve">
                <g>
                    <path style={{ fill: '#2A960D' }} d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027 c78.987,0,143.027-64.031,143.027-143.027C286.045,64.04,222.014,0,143.027,0z M143.027,259.236 c-64.183,0-116.209-52.026-116.209-116.209s52.026-116.2,116.209-116.2c64.174,0,116.209,52.017,116.209,116.2 S207.201,259.236,143.027,259.236z M196.832,125.149h-35.936V89.392c0-4.934-4.005-8.939-8.939-8.939h-17.878 c-4.943,0-8.939,4.005-8.939,8.939v35.757H89.401c-4.952,0-8.957,4.005-8.957,8.939v17.878c0,4.943,4.005,8.939,8.957,8.939h35.748 v35.676c0,4.934,3.996,8.93,8.939,8.93h17.878c4.934,0,8.939-3.996,8.939-8.93v-35.676h35.936c4.952,0,8.957-3.996,8.957-8.939 v-17.878C205.789,129.162,201.775,125.149,196.832,125.149z" />
                </g>
            </svg>
        </div>
    );
}


export function IconLess() {
    return (
        <div className="icon-less">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 286.054 286.054" style={{ enableBackground: 'new 0 0 286.054 286.054' }} xmlSpace="preserve">
                <g>
                    <path style={{ fill: '#2A960D' }} d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027 s143.027-64.031,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209 s52.026-116.2,116.209-116.2s116.209,52.017,116.209,116.2S207.21,259.236,143.027,259.236z M196.841,125.149H89.41 c-4.952,0-8.957,4.005-8.957,8.939v17.878c0,4.943,4.005,8.939,8.957,8.939h107.431c4.952,0,8.957-3.996,8.957-8.939v-17.878 C205.789,129.162,201.784,125.149,196.841,125.149z" />
                </g>
            </svg>
        </div>
    )
};



export function IconInterrogation() {
    return (
        <div className="icon-interrogation">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 493.508 493.508" style={{ enableBackground: 'new 0 0 493.508 493.508' }} xmlSpace="preserve">
                <g>
                    <g>
                        <path d="M421.38,72.426C374.82,25.786,312.86,0.07,246.736,0.006C110.804,0.006,0.116,110.578,0,246.506 c-0.064,65.916,25.548,127.904,72.1,174.56c46.568,46.628,108.284,72.436,174.196,72.436h0.572v-0.024 c134.956,0,246.54-110.596,246.64-246.516C493.572,181.05,467.924,119.07,421.38,72.426z M257.7,377.126 c-2.836,2.864-6.8,4.504-10.872,4.504c-4.088-0.008-8.064-1.656-10.932-4.56c-2.848-2.824-4.484-6.792-4.48-10.876 c0.008-4.072,1.664-8.064,4.532-10.924c2.856-2.848,7.064-4.488,10.92-4.488c4.06,0,8.028,1.648,10.86,4.504 c2.896,2.92,4.556,6.916,4.548,10.932C262.272,370.25,260.604,374.222,257.7,377.126z M265.124,282.674 c-1.76,0.388-3.02,1.948-3.024,3.752l-0.02,18.12c-0.008,8.488-6.916,15.408-15.436,15.408 c-8.512-0.008-15.412-6.94-15.404-15.436l0.036-34.964c0.008-8.484,6.916-15.08,15.416-15.08h0.068 c29.488,0,53.5-24.32,53.532-53.808c0.012-14.288-5.536-27.884-15.64-38.024c-10.14-10.132-23.568-15.788-37.884-15.804 c-29.492,0-53.512,23.956-53.544,53.464c-0.008,8.492-6.924,15.38-15.44,15.38c-4.12,0-7.996-1.624-10.9-4.544 c-2.916-2.912-4.508-6.788-4.504-10.904c0.044-46.492,37.896-84.324,84.448-84.324c46.532,0.048,84.344,37.936,84.3,84.46 C331.096,239.542,303.348,274.158,265.124,282.674z" />
                    </g>
                </g>
            </svg>
        </div>
    )
};