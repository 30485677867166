import React, { useEffect, useState } from "react";
import Footer from "./footer";
import "../assets/css/Sobrenos.css";
export default function Sobrenos() {
  return (
    <>
      <div className="card_sobrenos">
        
        
          <div className="body_sobrenos">
            <iframe
              className="iframesobrenos"
              loading="lazy"
              src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF4951BPZk&#x2F;view?embed"
              allowfullscreen="allowfullscreen"
              allow="fullscreen"
            ></iframe>
          </div>
          
       
        <div className="footer_sobrenos"></div>
      </div>
      <Footer />
    </>
  );
}
