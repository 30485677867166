import logoExtension from '../assets/img/logo_arb.png';
import { styleDataMarket } from '../global/GlobalVariables';
import { Tooltip } from 'react-tooltip';

function DataGame(props) {
  const {
    dataArb
  } = props;


  return (
    <table class="w-100 arbitragem-extension-desktop">
      <Tooltip id={`tooltip`} place="right"  style={{ maxWidth: '100%' }}/>
      <style>
        {
          `
            body{
            overflow: hidden;
            }
          `
        }

      </style>
      <tbody>
        <tr>
          <td class="p-0 m-0">


            <div class='row p-0 m-0'></div>
            <div className='header-extension-desktop'>
              <div className='strip-extension-desktop'>
              </div>

              <div className='conatainer-extension-desktop d-flex'>

                <div className='container-logo-desktop d-flex align-items-center'>
                  <div className='container-img-logo-desktop w-50'>
                    <img className='img-fluid' src={logoExtension} alt="logo-extension"/>
                  </div>


                </div>

                <div className='h-100 info-surebet-desktop d-flex fw-bold text-dark'>
                  <div className='w-25 h-100 d-flex flex-column justify-content-center'>
                    <div className='fs-title-extention-desktop truncate-text'
                    data-tooltip-id={`tooltip`}
                    data-tooltip-content={ dataArb['title_event'] }
                    data-tooltip-place="right"
                    >
                      {dataArb ? dataArb['title_event'] : '---'}
                    </div>
                    

                    <div className='fs-sub-title-extention-desktop truncate-text'
                      data-tooltip-id={`tooltip`}
                      data-tooltip-content={ dataArb['sub_title_event'] }
                      data-tooltip-place="right"
                    >
                      {dataArb ? dataArb['sub_title_event'] : '---'}
                    </div>
                  </div>

                  <div className='w-25 h-100 d-flex flex-column justify-content-center'>
                    <div className='fs-title-extention-desktop text-center'>
                      Mercado:
                    </div>

                    <div 
                      className='fs-sub-title-extention-desktop'
                      data-tooltip-id={`tooltip`}
                      data-tooltip-content={ dataArb['title'] === undefined ? dataArb['description_data_market']: dataArb['title']}  
                      data-tooltip-place="right"
                    >
                      {

                        dataArb.hasOwnProperty('description_data_market') ?
                          <p className=' truncate-text d-flex justify-content-center fs-sub-title-extention-desktop p-0 m-0 text-center'>
                            {styleDataMarket[dataArb['data_market']['type']]['componentFunction'](dataArb['data_market'], dataArb['description_data_market'])}

                          </p>
                          :

                          <div className={`d-flex flex-column justify-content-center  p-0 m-0 fs-sub-title-extention-desktop text-center`}>
                            <p
                              dangerouslySetInnerHTML={{ __html: dataArb['data_market'].replace("<abbr", '<abbr  class="title  truncate-text p-0 m-0 text-center"  style="text-decoration:auto;"').replace("minorc", "legenda-2").replace("minor", "legenda-2 text-secondary").replace("<sup", '<sup class="legenda-2"') }}
                            />
                          </div>
                      }
                    </div>
                    
                  </div>

                  <div className='w-25 h-100 d-flex align-items-center'>
                    <div className='w-50 text-center'>
                      <div className='fs-title-extention-desktop'>
                        Chance (ODD):
                      </div>
                      <div className='fs-sub-title-extention-desktop'>
                      {dataArb ? dataArb['chance'] : '---'}
                      </div>
                    </div>

                    <div className='w-50 text-center'>
                      <div className='fs-title-extention-desktop'>
                        Lucro (%):
                      </div>
                      <div className='fs-sub-title-extention-desktop'>
                      {dataArb ? dataArb['profit'] : '---'}
                      </div>
                    </div>
                  </div>

                  <div className='w-25 h-100 text-center d-flex flex-column justify-content-center'>
                    <div className='fs-title-extention-desktop text-danger truncate-text'>
                      Confira o mercado antes de apostar!
                    </div>

                    <div className='fs-sub-title-extention-desktop'>
                      

                      <a href="direct" className='fs-sub-title-extention-desktop text-dark fw-bold fs-normal-extension truncate-text'>
                      Guardar extensão
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </td>
        </tr>
        <script id="navigation"
          data-markers="{}">

        </script>
      </tbody>
    </table>

  );
}


export default DataGame;