import { Tooltip } from 'react-tooltip';
import logoExtension from '../assets/img/logo_arb.png';
import { styleDataMarket } from '../global/GlobalVariables';


function DataGameMobile(props) {
  const {
    dataArb
  } = props;


  return (
    <table class="w-100 arbitragem-extension-mobile">
      <style>
        {
          `
            body{
            overflow: hidden;
            }
          `
        }

      </style>
      <Tooltip id={`tooltip`} place='bottom-end' style={{ maxWidth: '100%' }} />
      <tbody>
        <tr>
          <td class="p-0 m-0">


            <div class='row p-0 m-0'></div>
            <div className='header-extension-mobile text-light fw-bold'>
              <div className='d-flex justify-content-around align-items-center area-logo-button-extension-mobile'>
                <div className='w-50 ps-2 d-flex align-items-center'>
                  <img className='img-logo-extension-mobile' src={logoExtension} />
                </div>

                <div className='w-50 text-end pe-2 fs-normal-extension-mobile'>
                <a href="direct" className='text-light fw-bold fs-normal-extension-mobile'>
                  Guardar extensão
                  </a>
                </div>
              </div>

              <div className='text-center custom-line-height-extension-mobile'>
                <span className='fs-normal-extension-mobile text-danger fw-bold'>
                  Confira o mercado antes de apostar!
                </span>
              </div>


              <div className='d-flex  d-flex align-items-center'>
                <div className='w-60 custom-line-height-extension-mobile custom-padding-start-extension-mobile'>
                  <div className='fs-title-extension-mobile truncate-text'
                    data-tooltip-id={`tooltip`}
                    data-tooltip-content={dataArb['title_event']}
                  >
                    {dataArb ? dataArb['title_event'] : '---'}
                  </div>
                  <div className='fs-normal-extension-mobile truncate-text'
                    data-tooltip-id={`tooltip`}
                    data-tooltip-content={dataArb['sub_title_event']}
                  >
                    {dataArb ? dataArb['sub_title_event'] : '---'}
                  </div>

                </div>
                <div className='w-40 custom-line-height-extension-mobile text-center'>
                  <div className='fs-title-extension-mobile'>
                    Mercado:
                  </div>
                  <div className='fs-normal-extension-mobile truncate-text'
                    data-tooltip-id={`tooltip`}
                    data-tooltip-content={dataArb['title'] === undefined ? dataArb['description_data_market'] : dataArb['title']}
                  >
                    {

                      dataArb.hasOwnProperty('description_data_market') ?
                        <p className=' truncate-text d-flex justify-content-center fs-normal-extension-mobile p-0 m-0 text-center text-light '>
                          {styleDataMarket[dataArb['data_market']['type']]['componentFunction'](dataArb['data_market'], dataArb['description_data_market'])}

                        </p>
                        :

                        <div className={`d-flex flex-column justify-content-center  p-0 m-0 fs-normal-extension-mobile text-center text-light truncate-text`}>
                          
                          <p
                            dangerouslySetInnerHTML={{ __html: dataArb['data_market'].replace("<abbr", '<abbr  class="title truncate-text p-0 m-0 text-center text-light"  style="text-decoration:auto;"').replace("minorc", "legenda-2").replace("minor", "legenda-2 text-secondary").replace("<sup", '<sup class="legenda-2"') }}
                          />
                        </div>
                    }
                  </div>

                </div>
              </div>

            </div>
          </td>
        </tr>
        <script id="navigation"
          data-markers="{}">

        </script>
      </tbody>
    </table>

  );
}


export default DataGameMobile;