import HeaderPageExtension from '../components/HeaderPageExtension';
import MainContainerShowExtension from '../components/MainContainerShowExtension';
import { checkSessiondID } from '../global/GlobalFunctions';
import { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';

function PageDownloadExtension(props) {
    const {
        statusMode,
        setStatusMode,
        handleLogout,
        isLoggedIn,
        showLogoutButton,
        notificationVolume,
        setNotificationVolume,
        userData,
    } = props;
    const intervalRef = useRef(null);

    const [queryFinished, setQueryFinished] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);


    function managerLoggin() {
        let actualUserData = JSON.parse(localStorage.userData);
        checkSessiondID(actualUserData.sessionID).then(data => {
            let newActualUserData = JSON.parse(localStorage.userData);
            setQueryFinished(true);

            
            if ((!data && newActualUserData.sessionID === actualUserData.sessionID) || (newActualUserData.sessionID === '')) {
                const allCookies = Cookies.get();

                for (let cookie in allCookies) {
                    Cookies.remove(cookie);
                }
                localStorage.clear();
                setUserLoggedIn(false);
                window.location.href = '/';
            } else {
                setUserLoggedIn(true);
            }

        }
        ).catch(error => {
            window.location.reload();
        }
        )
    }

    useEffect(() => {
        managerLoggin();
        intervalRef.current = setInterval(() => {
            managerLoggin();
        }
            , 3000);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [])

    return (
        <>
            {
                queryFinished === true && userLoggedIn === true ?
                    <>
                        <HeaderPageExtension
                            statusMode={statusMode}
                            setStatusMode={setStatusMode}
                            onLogout={handleLogout}
                            isLoggedIn={isLoggedIn}
                            showLogoutButton={showLogoutButton}
                            notificationVolume={notificationVolume}
                            setNotificationVolume={setNotificationVolume}
                            userData={userData}
                            user={userData}
                        />
                        <MainContainerShowExtension />
                    </>
                    :
                    ""
            }
        </>
    );
}


export default PageDownloadExtension;