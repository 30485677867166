import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import FilterMobile from "./FilterMobile";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { managerClonerBetHome } from "../global/GlobalVariables";
import { managerFunctionCloneBetHome } from "../global/GlobalFunctions";
import { IconYoutube } from "../global/ExternalIcon";

import { organizatorSurebetByClassification } from "../global/GlobalFunctions";
import Cookies from 'js-cookie';

let orderloop = [
  "first_home_bet",
  "second_home_bet",
  "third_home_bet",
  "fourth_home_bet",
  "fifth_home_bet",
  "sixth_home_bet",
  "seventh_home_bet",
  "eighth_home_bet",
  "ninth_home_bet",
  "tenth_home_bet",
];

const timeObject = {
  "6 Horas": 60 * 1000 * 60 * 6,
  "12 Horas": 60 * 1000 * 60 * 12,
  "16 Horas": 60 * 1000 * 60 * 16,
  "1 Dia": 60 * 1000 * 60 * 24,
};


function HideSurebet(props) {
  const params = useParams().id;

  const {
    valueMin,
    valueMax,
    betHome,
    sports,
    fullData,
    setFullData,
    timeEvent,
    itemsTrash,
    setItemsTrash,
    statusMode,
    controllerLocalStorage,
    setValueMin,
    setValueMax,
    setBetHome,
    quantityBetHomeEnabled,
    setQuantityBetHomeEnabled,
    setSports,
    quantitySportsEnabled,
    setQuantitySportsEnabled,
    setTimeEvent,
    setPopupWindow,
    popupWindow,
    filterOrder,
    setFilterOrder,
  } = props;


  const [olData, setOldData] = useState([]);
  const [data, setData] = useState([]);
  //const [fullData, setFullData] = useState([]);
  const [statusTrash, setStatusTrash] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  
  const [checkUpdate, setCheckUpdate] = useState("1");
  const [checkOpen, setCheckOpen] = useState("1");
  const [dataSurebetModified, setDataSurebetModified] = useState({});
  // Função para abrir uma janela pop-up do calculadora
  function openPopUpCalculator(value) {
    const url = `/calculator/${value}`;
    const windowName = "";


    
    localStorage.removeItem('dataCalculator');
    if (typeof fullData !== 'string'){       
      localStorage.setItem('dataCalculator', JSON.stringify(fullData) ) }
    else{       
      localStorage.setItem('dataCalculator', fullData);
    }
    // Verifique se a janela pop-up está aberta ou se é a primeira vez
    if (popupWindow && !popupWindow.closed) {
      const urls = `/calculator/${value}`;
      // Se estiver aberta, atualize o URL da janela existente

      // Adicione um manipulador de eventos para recarregar a página quando o hash do URL for alterado
      popupWindow.onhashchange = function () {
        popupWindow.location.reload();
      };
      popupWindow.location.hash = urls;
      return true;
    } else {
      // Se não estiver aberta, abra uma nova janela pop-up
      const windowFeatures = "width=800,height=460";

      setPopupWindow(window.open(url, windowName, windowFeatures));
      return true;
    }
    return true;
  }
  function openPopUpBet(value) {
    const url = `${value}`;
    const windowName = "popupWindow"; // Nome da janela pop-up
    const windowName2 = "popupWindow2"; // Nome da janela pop-up
    if (window[windowName] !== undefined) {
      window[windowName].close();
    }
    // Verifique se a janela pop-up já existe
    if (
      window[windowName] &&
      !window[windowName].closed &&
      window[windowName2] &&
      !window[windowName2].closed
    ) {
      // Atualize a URL da janela pop-up existente
      if (checkUpdate == "1") {
        window[windowName].location.href = url;
        setCheckUpdate("2");
      } else {
        window[windowName2].location.href = url;
        setCheckUpdate("1");
      }
    } else {
      if (checkOpen == "1") {
        // Se não houver uma janela pop-up aberta, abra uma nova
        setCheckOpen("2");
        const windowFeatures = "width=800,height=460";

        if (window[windowName] !== undefined) {
          window[windowName].close();
        }
        window[windowName] = window.open(url, windowName, windowFeatures);
      }
      if (checkOpen == "2") {
        setCheckOpen("1");
        // Se não houver uma janela pop-up aberta, abra uma nova
        const windowFeatures = "width=800,height=460";
        if (window[windowName2] !== undefined) {
          window[windowName2].close();
        }
        window[windowName2] = window.open(url, windowName2, windowFeatures);
      }
    }
  }
  function openPopUpAllBet(value, value2) {
    const url = `${value}`;
    const url2 = `${value2}`;
    const windowName = "popupWindowAll"; // Nome da janela pop-up
    const windowName2 = "popupWindowAll2"; // Nome da janela pop-up

    // Verifique se a janela pop-up já existe
    if (
      window[windowName] &&
      !window[windowName].closed &&
      window[windowName2] &&
      !window[windowName2].closed
    ) {
      window[windowName].location.href = url;
      window[windowName2].location.href = url2;
      return;
    } else {
      const windowFeatures = "width=800,height=460";
      const windowFeatures2 = "width=800,height=460";
      window[windowName] = window.open(url, windowName, windowFeatures);

      window[windowName2] = window.open(url2, windowName2, windowFeatures2);
      return;
    }
  }
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  function openPopUpCalculator(value, value2) {
    const url = `/calculator/${value}/${value2}`;
    const windowName = "";
    
    localStorage.removeItem('dataCalculator');
    if (typeof fullData !== 'string'){       
      localStorage.setItem('dataCalculator', JSON.stringify(fullData) ) }
    else{       
      localStorage.setItem('dataCalculator', fullData);
    }

    // Verifique se a janela pop-up está aberta ou se é a primeira vez
    if (popupWindow && !popupWindow.closed) {
      const urls = `/calculator/${value}/${value2}`;
      // Se estiver aberta, atualize o URL da janela existente

      // Adicione um manipulador de eventos para recarregar a página quando o hash do URL for alterado
      popupWindow.onhashchange = function () {
        popupWindow.location.reload();
      };
      popupWindow.location.hash = urls;

    } else {
      // Se não estiver aberta, abra uma nova janela pop-up
      const windowFeatures = "width=800,height=460";

      setPopupWindow(window.open(url, windowName, windowFeatures));
    }
  }

  const URL_SAFE_BET = "https://arbitragem.vps.webdock.cloud/safe_bet_master";
  const URL_DADOSPRE = "https://arbitragem.vps.webdock.cloud/safe_pro_master";
  const fetchData = () => {
    const b4726486 = Cookies.get('b4726486');
    let dataToSend = {
      'b652242': b4726486
    };

    fetch(URL_SAFE_BET,{
      method: 'POST',
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      body: JSON.stringify(dataToSend)
  })
      .then((response) => response.json())
      .then((json) => {
        let dataJsonConvertted = JSON.parse(json);

        // Lista de sites de apostas que você deseja duplicar
        const sitesToDuplicate = ["SportingBet", "Bwin"];
        const siteHome = [
          "https://sports.sportingbet.com/pt-br/sports",
          "https://sports.bwin.com/pt-br/sports",
        ];

        // Duplicar valores para "SportingBet" e "Bwin" quando o nome da aposta for "Betboo"
        for (const key in dataJsonConvertted) {
          let length_data = 0;

          for (let i = 0; i < orderloop.length; i++) {
            if (orderloop[i] && dataJsonConvertted[key]["main"][orderloop[i]]) {
              // Verifique se existe dado nesse dataJsonConvertted referente ao orderloop[i]
              length_data++;
            }
          }

          for (
            let indexOrderLoop = 0;
            indexOrderLoop < length_data;
            indexOrderLoop++
          ) {
            let keyOrderBethome = orderloop[indexOrderLoop];

            try {
              const namebet =
                dataJsonConvertted[key]["main"][keyOrderBethome]["name_bet_home"];

              if (namebet.toLowerCase() === "betboo") {
                // Crie um novo objeto com os dados duplicados
                const newBetData = {
                  ...dataJsonConvertted[key]["main"][keyOrderBethome],
                };

                for (const site of sitesToDuplicate) {
                  // Atualize o nome da aposta para o nome do site atual
                  newBetData["name_bet_home"] = site;

                  let urlBetHome = newBetData["url_bet"];

                  // Atualize o link do site com base no nome do site atual
                  if (site.toLowerCase() === "sportingbet") {
                    if (urlBetHome.includes(".sportingbet.")) {
                      // Substitui "/en/" por "/pt/"
                      urlBetHome = urlBetHome.replace(
                        ".sportingbet.",
                        ".sportingbet."
                      );
                      newBetData["url_bet_home"] = siteHome[0];
                      newBetData["url_bet"] = urlBetHome;
                      // Atualize os dados duplicados no objeto original
                      let keyOrderBethomes = orderloop[length_data];
                      dataJsonConvertted[key]["main"][keyOrderBethomes] =
                        newBetData;

                    }
                  } else if (site.toLowerCase() === "bwin") {
                    if (urlBetHome.includes(".sportingbet.")) {
                      // Substitui "/en/" por "/pt/"
                      urlBetHome = urlBetHome.replace(".sportingbet.", ".bwin.");
                      newBetData["url_bet_home"] = siteHome[1];
                      newBetData["url_bet"] = urlBetHome;
                      // Atualize os dados duplicados no objeto original
                      let keyOrderBethomes = orderloop[length_data + 1];
                      dataJsonConvertted[key]["main"][keyOrderBethomes] =
                        newBetData;

                    }
                  }
                }
              }
            } catch (error) {
              console.log("Key error", error);
            }
          }
        }


        let resultSearchBet = dataJsonConvertted[params];

        if (resultSearchBet === undefined) {
          setData({ error: "Erro apostas não encontrada" });
        } else {
          for (
            let i = 0;
            i < dataJsonConvertted[params]["group_events"].length;
            i++
          ) {
            let dataBet = dataJsonConvertted[params]["group_events"][i]["main"];
            let allTime = [];
            for (
              let indexOrder = 0;
              indexOrder < orderloop.length;
              indexOrder++
            ) {
              let order = orderloop[indexOrder];
              if (order in dataBet) {
                allTime.push(Number(dataBet[order]["time_milliseconds"]));
              }
            }

            for (let indexTime = 0; indexTime < allTime.length; indexTime++) {
              let time = allTime[indexTime];
              let statusLoop = true;
              for (
                let indexTime2 = 0;
                indexTime2 < allTime.length;
                indexTime2++
              ) {
                let time2 = allTime[indexTime2];
                let resultCalculate = time - time2;
                if (resultCalculate > 900000 || resultCalculate < -900000) {
                  dataJsonConvertted[params]["group_events"][i]["main"][
                    "bgTime"
                  ] = true;
                  statusLoop = false;
                  break;
                }
              }
              if (!statusLoop) {
                break;
              }
            }
          }
          
          setFullData(dataJsonConvertted);
        }
      });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkBetHomeEnabled(game) {
    const betHomeEnabled = JSON.parse(localStorage.listBehomeEnabled);

    for (
      let indexOrderLoop = 0;
      indexOrderLoop < orderloop.length;
      indexOrderLoop++
    ) {
      let keyOrderBethome = orderloop[indexOrderLoop];
      if (keyOrderBethome in game["main"]) {

        let betHomeIsEnable = betHomeEnabled.includes(
          game["main"][keyOrderBethome]["name_bet_home"].toLowerCase().trim()
        );

        let nameBetHomeLower = game["main"][keyOrderBethome]["name_bet_home"].toLowerCase().trim();
        
        if (managerClonerBetHome['allBetHomeAbleToClone'].includes(nameBetHomeLower)) {

          for (let i = 0; i < managerClonerBetHome[nameBetHomeLower]['allBetHomeClone'].length; i++) {
            let nameBetHomeToClone = managerClonerBetHome[nameBetHomeLower]['allBetHomeClone'][i];

            if (betHomeEnabled.includes(nameBetHomeToClone) && !betHomeEnabled.includes(nameBetHomeLower)) {
              let actualURLBet = game["main"][keyOrderBethome]["url_bet"];

              let newURLBet = managerFunctionCloneBetHome(
                managerClonerBetHome,
                nameBetHomeLower,
                nameBetHomeToClone,
                actualURLBet
              );

              game["main"][keyOrderBethome]["url_bet_home"] = managerClonerBetHome[nameBetHomeLower][nameBetHomeToClone]['baseURL'];
              game["main"][keyOrderBethome]["url_bet"] = newURLBet;
              game["main"][keyOrderBethome]["name_bet_home"] = managerClonerBetHome[nameBetHomeLower][nameBetHomeToClone]['nameBetHome'];
              nameBetHomeLower = managerClonerBetHome[nameBetHomeLower][nameBetHomeToClone]['nameBetHome'].toLowerCase().trim();
              
              break;
            }

          }
        }


        betHomeIsEnable = betHomeEnabled.includes(nameBetHomeLower);

        if (betHomeIsEnable === false) {
          return false;
        }
      }
    }
    return true;
  }

  function managerBetView(fullGame, gameMain = []) {
    let resultBetHomeChecked = checkBetHomeEnabled(fullGame);

    return resultBetHomeChecked;
  }
  function betHomeView(fullGame) {
    let bet = managerBetView(fullGame, [fullGame]);

    return bet;
  }

  useEffect(() => {

    let newValueData = {};
    if (fullData[params] !== undefined && Object.keys(fullData).length > 0) {

      for (let keyObject in fullData[params]["group_events"]) {
        let valueObject = fullData[params]["group_events"][keyObject];

        let newValueDataObject = betHomeView(valueObject);
        if (!newValueDataObject) {
          continue;
        }

        let profit = parseFloat(
          valueObject["main"]["profit"].replace("%", "").replace(",", ".")
        );
        if (!(valueMin <= profit && profit <= valueMax)) {
          continue;
        } else if (
          !JSON.parse(localStorage.listSportsEnabled).includes(
            valueObject["main"]["first_home_bet"]["sport"].toLowerCase()
          )
        ) {
          continue;
        }
        if (timeEvent !== "Qualquer Horário") {
          let jumpLoop = false;
          for (let i = 0; i < orderloop.length; i++) {
            let order = orderloop[i];

            if (order in valueObject["main"]) {
              let timeGame = parseInt(
                valueObject["main"][order]["time_milliseconds"]
              );
              let timeCalculated = timeGame - new Date().getTime();

              if (timeCalculated >= timeObject[timeEvent]) {
                jumpLoop = true;
                break;
              }
            }
          }
          if (jumpLoop) {
            continue;
          }
        }
        if (
          itemsTrash.includes(valueObject["main"]["other_key_bet"]) ===
          !statusTrash
        ) {
          continue;
        }
        newValueData[keyObject] = valueObject;

      }
      let newObjectOrdenated = {};
      let objectKeyProfit = {};
      let listProfitToOrdenate = [];
      let keysObject = Object.keys(newValueData);

      for (let i = 0; i < keysObject.length; i++) {
        let keyObject = newValueData[keysObject[i]]["main"]["profit"]
          .replaceAll(",", ".")
          .replaceAll("%", "");
        objectKeyProfit[Number(keyObject).toString()] = keysObject[i];
        listProfitToOrdenate.push(Number(keyObject));
      }

      listProfitToOrdenate
        .sort(function (a, b) {
          return a - b;
        })
        .reverse();

      for (let i = 0; i < listProfitToOrdenate.length; i++) {
        let keyValueProfit = listProfitToOrdenate[i].toString();
        newObjectOrdenated[objectKeyProfit[keyValueProfit]] =
          newValueData[objectKeyProfit[keyValueProfit]];
      }
      setOldData(newObjectOrdenated)
      let dataSurebetOrganized = organizatorSurebetByClassification(filterOrder, newObjectOrdenated );
      setData(dataSurebetOrganized);
    }
    localStorage.setItem("trash", JSON.stringify(itemsTrash));
  }, [
    fullData,
    valueMin,
    valueMax,
    betHome,
    sports,
    timeEvent,
    itemsTrash,
    statusTrash,
  ]);


  useEffect(() => {
    let dataSurebetOrganizedExpress = organizatorSurebetByClassification(filterOrder, olData);
    setData(dataSurebetOrganizedExpress);
  }, [filterOrder]);

  function eventTrash(nameEvente, statusEvent) {
    if (!statusEvent) {
      setItemsTrash((current) => [...current, nameEvente]);
    } else {
      setItemsTrash(itemsTrash.filter((item) => item !== nameEvente));
    }
  }

  function handleClick(value) {
    // ... Outras lógicas ...
    const betHomeEnabled = JSON.parse(localStorage.listBehomeEnabled);

    try {
      let endereco = "";
      let endereco2 = "";
      let verUrls = "1";

      // Loop para processar URLs
      orderloop.map((newNameOrder, newIndexOrder) => {
        if (newNameOrder in data[value]["main"]) {
          let url = data[value]["main"][newNameOrder]["url_bet"];

          // Verifica e modifica a URL
          if (url.includes("/en/") && data[value]["main"][newNameOrder]['name_bet_home'].toLowerCase().replaceAll(' ', '') !== 'kto') {
            url = url.replace("/en/", "/pt/");
          }
          // Verifique se a Betboo está habilitada nas configurações
          if (betHomeEnabled.includes("betboo")) {
            url = url.replace(".sportingbet.", ".br.betboo."); // Altere a URL para Betboo
          }
          // Verifique se a Sportingbet está habilitada nas configurações
          else if (betHomeEnabled.includes("sportingbet")) {
            url = url.replace(".br.betboo.", ".sportingbet."); // Altere a URL para Sportingbet
          }
          // Verifique se a Bwin está habilitada nas configurações
          else if (betHomeEnabled.includes("bwin")) {
            url = url.replace(".br.betboo.", ".bwin."); // Altere a URL para Bwin
          }

          // Define endereco e endereco2 com base na condição
          if (verUrls === "1") {
            verUrls = "2";
            endereco = url;
          } else {
            verUrls = "1";
            endereco2 = url;
          }
        }
        return null;
      });

      // Abre a segunda janela pop-up
      openPopUpAllBet(endereco, endereco2);
      // Abre a primeira janela pop-up
      openPopUpCalculator(value);
    } catch (error) {
      console.error("Ocorreu um erro:", error);
    }
  }


  return (
    <div
      className={`w-100 border border-2 ${statusMode ? "borderColor" : ""
        } rounded p-0 d-block ms-2  mt-3`}
    >
      <Row>
        <Col className="borderstyle">
          <div
            className={`py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
              } fw-bold text-center `}
            
          >
            <button
              className={`text-title-apostas py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
                } fw-bold text-center `}
              
            >
              ARBITRAGENS PRÉ-EVENTO
            </button>
          </div>
        </Col>
        <Col className="borderstyle">
          <div
            className={`py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
              } fw-bold text-center `}
            
          >
            <button
              className={`text-title-apostas py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
                } fw-bold text-center `}
            >
              ARBITRAGENS AO VIVO
            </button>
          </div>
        </Col>
      </Row>

      <div
        className={` py-2 border-bottom button-hidesurebet ${statusMode ? "borderColor textGray" : ""
          } fw-bold text-center `}
      >
        <NavLink to="/">
          <button className={`text-Filter p-0 m-0 fw-bold text-center button-hidesurebet-color ${statusMode ? "textGray" : ""
            }`}
            style={{ background: "#0069d9", display: "flex", padding: "5px", borderRadius: "5px", margin: "5px" }}>

            <img
              style={{
                filter: "invert(100%)",
                width: 20,
                height: 20,
              }}
              src="https://img.icons8.com/ios-filled/50/000000/left2.png"
              alt="left2"
            />
            <p className="m-0 fontSizeDefaultDevice">
              Voltar
            </p>
          </button>
        </NavLink>
      </div>

      <div
        className="py-3 d-flex justify-content-between align-items-center"
        style={{ backgroundColor: statusMode ? "#191c1f" : "#eceff3" }}
      >
        <div className="d-flex">
          <button
            type="button"
            className="h-50 mx-3 d-flex justify-content-center align-items-center btn btn-warning btn-sm fontSizeDefaultDevice"
            onClick={() => {
              window.open("/calculator/", "", "width=800,height=460");
            }}
          >
            <p className="calculatorrespon">Calculadora</p>
            <img
              className="img-listagem-topo"
              width="25"
              height="25"
              src="https://img.icons8.com/color/48/calculator--v1.png"
              alt="calculator--v1"
            />
          </button>
          <p className="m-0"></p>
          <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Calculadora</Modal.Title>
            </Modal.Header>
            <Modal.Body>{/* Conteúdo da calculadora aqui */}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
          <button
            type="button"
            className={`h-50 btn ${statusTrash ? "btn-primary" : "btn-danger"
              } btn-sm`}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={() => setStatusTrash(!statusTrash)}
            >
              {statusTrash ? (
                <>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 14 16"
                    className="me-2 iconCalculator"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 3.5c3.92.44 8 3.125 8 10-2.312-5.062-4.75-6-8-6V11L.5 5.5 6 0v3.5z"
                    ></path>
                  </svg>
                  <p className="m-0 fontSizeDefaultDevice">Voltar aos jogos</p>
                </>
              ) : (
                <>
                  <p className="m-0 fontSizeDefaultDevice calculatorrespon">
                    Lixeira
                  </p>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 12 16"
                    className="ms-1 iconCalculator"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M11 2H9c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1H2c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1v9c0 .55.45 1 1 1h7c.55 0 1-.45 1-1V5c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 12H3V5h1v8h1V5h1v8h1V5h1v8h1V5h1v9zm1-10H2V3h9v1z"
                    ></path>
                  </svg>
                </>
              )}
            </div>
          </button>

          <button
            id="back_game"
            type="button"
            className="h-50 btn btn-sm btn-primary d-none"
          >
            <div className="d-flex justify-content-center align-items-center">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 14 16"
                className="me-2"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "25px", height: "25px" }}
              >
                <path
                  fillRule="evenodd"
                  d="M6 3.5c3.92.44 8 3.125 8 10-2.312-5.062-4.75-6-8-6V11L.5 5.5 6 0v3.5z"
                ></path>
              </svg>
              <p className="m-0">Voltar aos jogos</p>
            </div>
          </button>
          <a  class=" d-flex alig-items-center btn-pop-up-redirect" href="https://youtu.be/Ddxg8PsqI30" target='_blank'>
                <a class="linknavbar d-flex"><b class="text-light d-flex align-items-center fontSizeDefaultDevice">POP-UPS </b>
                
                </a>
                <div className='icon-video-pop-up'>
                <IconYoutube/>
                </div>
            </a>
        </div>
        
        <div className="me-3">
          <FilterMobile
            statusMode={statusMode}
            controllerLocalStorage={controllerLocalStorage}
            valueMin={valueMin}
            setValueMin={setValueMin}
            valueMax={valueMax}
            setValueMax={setValueMax}
            betHome={betHome}
            setBetHome={setBetHome}
            quantityBetHomeEnabled={quantityBetHomeEnabled}
            setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
            sports={sports}
            setSports={setSports}
            quantitySportsEnabled={quantitySportsEnabled}
            setQuantitySportsEnabled={setQuantitySportsEnabled}
            timeEvent={timeEvent}
            setTimeEvent={setTimeEvent}
            filterOrder={filterOrder}
            setFilterOrder={setFilterOrder}
          />
        </div>
      </div>

      {Object.keys(data).includes("error") ? (
        <>
          <style>{`
                body
                #containerFindBet{
                    margin:0;
                    padding:0;
                    background:#262626;
                    height: 600px
                  }
                  .ring{
                    line-height: 1.5!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position:relative;
                    top:50%;
                    left:50%;
                    transform:translate(-50%,-50%);
                    width:380px;
                    height:380px;
                    background:transparent;
                    border:3px solid #3c3c3c;
                    border-radius:50%;
                    text-align:center;
                    line-height:150px;
                    font-family:sans-serif;
                    font-size:20px;
                    color:#ff0000;
                    letter-spacing:4px;
                    text-transform:uppercase;
                    text-shadow:0 0 10px #ff0000;
                    box-shadow:0 0 20px rgba(0,0,0,.5);
                    border:3px solid #ff0000;
                  }
                  .ring a{
                    font-size:12px!important;
                    text-transform:uppercase;
                    text-shadow:0 0 10px #ff0000;
                  }
                                  
                `}</style>

          <div id="containerFindBet">
            <div className="ring flex-column">
              Apostas não enconstrada
              <br />
              por favor
              <br />
              tente mais tarde
              <a
                href="/"
                className="btn btn-outline-danger"
                style={{ position: "relative", bottom: "-35px" }}
              >
                Voltar para o inicio
              </a>
            </div>
          </div>
        </>
      ) : Object.keys(data).length > 0 || statusTrash ? (
        <table className="w-100">
          <thead>
            <tr className="greenArbitragem py-1 text-light">
              <th
                className={`fw-bold fontSizeDefaultDevice text-center ${statusMode ? "textDark" : "text-light"
                  } p-0 profitDesktop`}
                colSpan={2}
              >
                Lucro
              </th>
              <th
                className={`fw-bold fontSizeDefaultDevice text-center ${statusMode ? "textDark" : "text-light"
                  } p-0 profitMobile`}
              >
                Lucro
              </th>
              <th
                className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"
                  } betHome profitDesktop`}
                style={{ whiteSpace: "nowrap" }}
              >
                Casa de aposta
              </th>
              <th
                className={`fw-bold fontSizeDefaultDevice text-center ${statusMode ? "textDark" : "text-light"
                  } p-0`}
              >
                Data
              </th>
              <th
                className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"
                  } p-0`}
              >
                Evento
              </th>
              <th
                className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"
                  } p-0`}
              >
                Mercado
              </th>
              <th
                className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"
                  } p-0`}
              >
                Chance
              </th>
              <th
                className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"
                  } p-0`}
              ></th>
            </tr>
          </thead>
          {Object.keys(data).map((value, index) => (
            <tbody className={`${statusMode ? "bgDark" : ""}`} key={index}>

              <tr className="align-end ">
                <td className="p-0" colSpan={10}>
                  <div
                    className={`d-flex justify-content-between profitTrashCalculatorMobile ${statusMode
                      ? "backgroundProfitMobileDark"
                      : index % 2 === 0
                        ? "backgroundProfitMobileLight"
                        : "backgroundProfitMobileLight2"
                      }`}
                  >
                    <div className="d-flex align-items-end">
                      <p
                        className="m-0 text-success fw-bold fs-5 profit text-center"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {data[value]["main"]["profit"]}
                      </p>

                      <div className="d-flex align-items-end">
                        <span
                          className={`ps-1 m-0 legenda ${statusMode ? "textGray1" : "text-black-50"
                            } fontSizeDefaultDevice1`}
                          style={{ height: "1.59574vw" }}
                        >
                          {data[value]["main"]["time"]}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        className="p-0 mx-1 btn btn-warning text-decoration-none d-flex justify-content-center align-items-center defaultSizeBtnTrashCalculatorDevice"
                        title="Calculadora de aposta segura"
                        onClick={() => {
                          openPopUpCalculator(params, value);
                        }}
                        style={{ width: "25px", height: "25px" }}
                      >
                        <svg
                          className="iconCalculator"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1"
                          viewBox="0 0 48 48"
                          enableBackground="new 0 0 48 48"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: "25px", height: "25px" }}
                        >
                          <path
                            fill="#616161"
                            d="M40,16H8v24c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V16z"
                          ></path>
                          <path
                            fill="#424242"
                            d="M36,4H12C9.8,4,8,5.8,8,8v9h32V8C40,5.8,38.2,4,36,4z"
                          ></path>
                          <path
                            fill="#9CCC65"
                            d="M36,14H12c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h24c0.6,0,1,0.4,1,1v5C37,13.6,36.6,14,36,14z"
                          ></path>
                          <g fill="#33691E">
                            <rect x="33" y="10" width="2" height="2"></rect>
                            <rect x="29" y="10" width="2" height="2"></rect>
                          </g>
                          <path
                            fill="#FF5252"
                            d="M36,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,22.6,36.6,23,36,23z"
                          ></path>
                          <g fill="#E0E0E0">
                            <path d="M15,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,22.6,15.6,23,15,23z"></path>
                            <path d="M22,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,22.6,22.6,23,22,23z"></path>
                            <path d="M29,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,22.6,29.6,23,29,23z"></path>
                            <path d="M15,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,28.6,15.6,29,15,29z"></path>
                            <path d="M22,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,28.6,22.6,29,22,29z"></path>
                            <path d="M29,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,28.6,29.6,29,29,29z"></path>
                            <path d="M15,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,34.6,15.6,35,15,35z"></path>
                            <path d="M22,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,34.6,22.6,35,22,35z"></path>
                            <path d="M29,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,34.6,29.6,35,29,35z"></path>
                            <path d="M15,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,40.6,15.6,41,15,41z"></path>
                            <path d="M22,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,40.6,22.6,41,22,41z"></path>
                            <path d="M29,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,40.6,29.6,41,29,41z"></path>
                          </g>
                          <g fill="#BDBDBD">
                            <path d="M36,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,28.6,36.6,29,36,29z"></path>
                            <path d="M36,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,34.6,36.6,35,36,35z"></path>
                            <path d="M36,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,40.6,36.6,41,36,41z"></path>
                          </g>
                        </svg>
                      </button>
                      <button
                        type="button"
                        className={`all_trash p-0  d-flex justify-content-center align-items-center btn ${statusTrash ? "btn-primary" : "btn-danger"
                          } defaultSizeBtnTrashCalculatorDevice`}
                        style={{ width: "25px", height: "25px" }}
                        onClick={() =>
                          eventTrash(
                            data[value]["main"]["other_key_bet"],
                            statusTrash
                          )
                        }
                      >
                        {statusTrash ? (
                          <>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 14 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              className="iconTrash"
                              style={{ width: "16px", height: "16px" }}
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 3.5c3.92.44 8 3.125 8 10-2.312-5.062-4.75-6-8-6V11L.5 5.5 6 0v3.5z"
                              ></path>
                            </svg>
                          </>
                        ) : (
                          <>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 12 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              className="iconTrash"
                              style={{ width: "16px", height: "16px" }}
                            >
                              <path
                                fillRule="evenodd"
                                d="M11 2H9c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1H2c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1v9c0 .55.45 1 1 1h7c.55 0 1-.45 1-1V5c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 12H3V5h1v8h1V5h1v8h1V5h1v8h1V5h1v9zm1-10H2V3h9v1z"
                              ></path>
                            </svg>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="align-top mt-2 profitDesktop">
                <td
                  className="ps-1 text-center padding_top profitTime"
                  rowSpan="5"
                  colSpan="2"
                  style={{ lineHeight: "16px" }}
                >
                  <p
                    className="m-0 mt-3 pb-0  text-success fw-bold fs-5 profit d-flex justify-content-center text-center"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {data[value]["main"]["profit"]}
                  </p>

                  <span
                    className={`m-0 legenda ${statusMode ? "textGray1" : "text-black-50"
                      } fontSizeDefaultDevice1`}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {data[value]["main"]["time"]}
                  </span>
                  <div className="d-flex justify-content-center">
                    <button
                      className="p-0 m-1 btn btn-warning text-decoration-none d-flex justify-content-center align-items-center defaultSizeBtnTrashCalculatorDevice"
                      title="Calculadora de aposta segura"
                      onClick={() => {
                        openPopUpCalculator(params, value);
                      }}
                      style={{ width: "25px", height: "25px" }}
                    >
                      <svg
                        className="iconCalculator"
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        version="1"
                        viewBox="0 0 48 48"
                        enableBackground="new 0 0 48 48"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: "25px", height: "25px" }}
                      >
                        <path
                          fill="#616161"
                          d="M40,16H8v24c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V16z"
                        ></path>
                        <path
                          fill="#424242"
                          d="M36,4H12C9.8,4,8,5.8,8,8v9h32V8C40,5.8,38.2,4,36,4z"
                        ></path>
                        <path
                          fill="#9CCC65"
                          d="M36,14H12c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h24c0.6,0,1,0.4,1,1v5C37,13.6,36.6,14,36,14z"
                        ></path>
                        <g fill="#33691E">
                          <rect x="33" y="10" width="2" height="2"></rect>
                          <rect x="29" y="10" width="2" height="2"></rect>
                        </g>
                        <path
                          fill="#FF5252"
                          d="M36,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,22.6,36.6,23,36,23z"
                        ></path>
                        <g fill="#E0E0E0">
                          <path d="M15,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,22.6,15.6,23,15,23z"></path>
                          <path d="M22,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,22.6,22.6,23,22,23z"></path>
                          <path d="M29,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,22.6,29.6,23,29,23z"></path>
                          <path d="M15,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,28.6,15.6,29,15,29z"></path>
                          <path d="M22,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,28.6,22.6,29,22,29z"></path>
                          <path d="M29,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,28.6,29.6,29,29,29z"></path>
                          <path d="M15,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,34.6,15.6,35,15,35z"></path>
                          <path d="M22,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,34.6,22.6,35,22,35z"></path>
                          <path d="M29,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,34.6,29.6,35,29,35z"></path>
                          <path d="M15,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,40.6,15.6,41,15,41z"></path>
                          <path d="M22,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,40.6,22.6,41,22,41z"></path>
                          <path d="M29,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,40.6,29.6,41,29,41z"></path>
                        </g>
                        <g fill="#BDBDBD">
                          <path d="M36,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,28.6,36.6,29,36,29z"></path>
                          <path d="M36,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,34.6,36.6,35,36,35z"></path>
                          <path d="M36,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,40.6,36.6,41,36,41z"></path>
                        </g>
                      </svg>
                    </button>
                    <button
                      type="button"
                      className={`all_trash p-0 m-1 d-flex justify-content-center align-items-center btn ${statusTrash ? "btn-primary" : "btn-danger"
                        } defaultSizeBtnTrashCalculatorDevice`}
                      style={{ width: "25px", height: "25px" }}
                      onClick={() =>
                        eventTrash(
                          data[value]["main"]["other_key_bet"],
                          statusTrash
                        )
                      }
                    >
                      {statusTrash ? (
                        <>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 14 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className="iconTrash"
                            style={{ width: "16px", height: "16px" }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M6 3.5c3.92.44 8 3.125 8 10-2.312-5.062-4.75-6-8-6V11L.5 5.5 6 0v3.5z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 12 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className="iconTrash"
                            style={{ width: "16px", height: "16px" }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M11 2H9c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1H2c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1v9c0 .55.45 1 1 1h7c.55 0 1-.45 1-1V5c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 12H3V5h1v8h1V5h1v8h1V5h1v8h1V5h1v9zm1-10H2V3h9v1z"
                            ></path>
                          </svg>
                        </>
                      )}
                    </button>
                  </div>
                </td>
              </tr>

              {orderloop.map((nameOrder, indexOrder) => {
                if (nameOrder in data[value]["main"]) {
                  return (
                    <tr className="align-center" key={indexOrder}>
                      <td className="text-start betHome">
                        <div className="d-flex flex-column fontSizeDefaultDevice">
                          <div
                            className={`link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ${nameOrder === "first_home_bet"
                                ? "pt-2 paddingTopMibile"
                                : ""
                              } ${statusMode ? "colorLink" : ""}`}
                            onClick={() => {
                              const nameBetHome =
                                data[value]["main"][nameOrder][
                                  "name_bet_home"
                                ].toLowerCase();
                              let urlBetHome =
                                data[value]["main"][nameOrder][
                                "url_bet_home"
                                ];

                              openPopUpBet(urlBetHome);

                              //window.open(urlBetHome, "_blank");
                            }}
                            role="button"
                            style={{ whiteSpace: "pre" }}
                          >
                            {(() => {
                              const nameBetHome =
                                data[value]["main"][nameOrder][
                                  "name_bet_home"
                                ].toLowerCase();

                              // Verifique se é uma das três casas de apostas
                              if (
                                [
                                  "bet365 (full)",
                                  "betano (br)",
                                  "bodog (en)",
                                  "vbet (lat)",
                                  "kto",
                                ].includes(nameBetHome)
                              ) {
                                // Realize a substituição do nome
                                switch (nameBetHome) {
                                  case "bet365 (full)":
                                    return "Bet365";
                                  case "betano (br)":
                                    return "Betano";
                                  case "bodog (en)":
                                    return "Bodog";
                                  case "vbet (lat)":
                                    return "Vbet";
                                  default:
                                    break;
                                }
                              }

                              // Se não for uma das três casas, mantenha o nome original
                              return data[value]["main"][nameOrder][
                                "name_bet_home"
                              ];
                            })()}
                          </div>

                          <span
                            className={`m-0 legenda-2  ${statusMode ? "textGray1" : "text-black-50"
                              } fontSizeDefaultDevice2`}
                          >
                            {data[value]["main"][nameOrder]["sport"]}
                          </span>
                        </div>
                      </td>

                      <td className="col-1 text-start">
                        <div
                          className={`d-flex flex-column text-center justify-content-center align-items-center  ${statusMode ? "textGray2" : ""
                            }`}
                        >
                          <span className="fontSizeDefaultDevice">
                            {data[value]["main"][nameOrder]["date_event"]}
                          </span>

                          <span
                            className={`badge rounded-pill ${statusMode ? "textDark2" : "text-light"
                              } ${data[value]["main"]["bgTime"] !== undefined
                                ? "bg-danger"
                                : "bg-success"
                              } fontSizeBadgeDefaultDevice`}
                          >
                            {data[value]["main"][nameOrder]["time_event"]}
                          </span>
                        </div>
                      </td>

                      <td className="text-start fontSizeDefaultDevice">
                        <div className={`d-flex flex-column `}>
                          <div
                            className={` text-decoration-underline link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ${statusMode ? "colorLink" : ""
                              }`}
                            onClick={() => {
                              const betHomeEnabled = JSON.parse(
                                localStorage.listBehomeEnabled
                              );
                              let url =
                                data[value]["main"][nameOrder][
                                "url_bet"
                                ];

                              // Verifica se a URL contém "/en/"
                              if (url.includes("/en/") && data[value]["main"][nameOrder]['name_bet_home'].toLowerCase().replaceAll(' ', '') !== 'kto') {
                                // Substitui "/en/" por "/pt/"
                                url = url.replace("/en/", "/pt/");
                              }
                              if (betHomeEnabled.includes("betboo")) {
                                url = url.replace(
                                  ".sportingbet.",
                                  ".br.betboo."
                                ); // Altere a URL para Betboo
                              }
                              // Verifique se a Sportingbet está habilitada nas configurações
                              else if (
                                betHomeEnabled.includes("sportingbet")
                              ) {
                                url = url.replace(
                                  ".br.betboo.",
                                  ".sportingbet."
                                ); // Altere a URL para Sportingbet
                              }
                              // Verifique se a Bwin está habilitada nas configurações
                              else if (
                                betHomeEnabled.includes("bwin")
                              ) {
                                url = url.replace(
                                  ".br.betboo.",
                                  ".bwin."
                                ); // Altere a URL para Bwin
                              }
                              // Verifica se a URL contém "/en/"
                              openPopUpBet(url);

                              //window.open(url, "_blank");
                            }}
                            role="button"
                          >
                            {
                              data[value]["main"][nameOrder][
                              "title_event"
                              ]
                            }
                          </div>

                          <span
                            className={`m-0 legenda-2 ${statusMode ? "textGray1" : "text-black-50"
                              } fontSizeDefaultDevice2`}
                          >
                            {data[value]["main"][nameOrder]["sub_title_event"]}
                          </span>
                        </div>
                      </td>

                      <td className="text-start fontSizeDefaultDevice">
                        <div
                          className={`d-flex flex-column d-flex flex-column h-100 `}
                        >
                          <p
                            className={`${statusMode ? "textGray2" : ""}`}
                            dangerouslySetInnerHTML={{
                              __html: data[value]["main"][nameOrder][
                                "data_market"
                              ]
                                .replace(
                                  "<abbr",
                                  '<abbr  style="text-decoration:auto;"'
                                )
                                .replace("minorc", "legenda-2")
                                .replace("minor", "legenda-2 text-secondary")
                                .replace("<sup", '<sup class="legenda-2"'),
                            }}
                          />
                        </div>
                      </td>

                      <td className="text-start fontSizeDefaultDevice">
                        <div
                          className={`d-flex flex-column d-flex flex-column `}
                        >
                          <div
                            role="button"
                            className={`chance-main-bet link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover  ${statusMode ? "colorLink" : ""
                              }`}
                            onClick={() => {

                              const betHomeEnabled = JSON.parse(
                                localStorage.listBehomeEnabled
                              );
                              let url =
                                data[value]["main"][nameOrder][
                                "url_bet"
                                ];

                              // Verifica se a URL contém "/en/"
                              if (url.includes("/en/") && data[value]["main"][nameOrder]['name_bet_home'].toLowerCase().replaceAll(' ', '') !== 'kto') {
                                // Substitui "/en/" por "/pt/"
                                url = url.replace("/en/", "/pt/");
                              }
                              if (betHomeEnabled.includes("betboo")) {
                                url = url.replace(
                                  ".sportingbet.",
                                  ".br.betboo."
                                ); // Altere a URL para Betboo
                              }
                              // Verifique se a Sportingbet está habilitada nas configurações
                              else if (
                                betHomeEnabled.includes("sportingbet")
                              ) {
                                url = url.replace(
                                  ".br.betboo.",
                                  ".sportingbet."
                                ); // Altere a URL para Sportingbet
                              }
                              // Verifique se a Bwin está habilitada nas configurações
                              else if (
                                betHomeEnabled.includes("bwin")
                              ) {
                                url = url.replace(
                                  ".br.betboo.",
                                  ".bwin."
                                ); // Altere a URL para Bwin
                              }
                              // Verifica se a URL contém "/en/"
                              openPopUpBet(url);


                            }}
                            style={{ paddingTop: "2px!important" }}
                          >
                            {data[value]["main"][nameOrder]["chance"]}
                          </div>
                        </div>
                      </td>

                      {nameOrder === "first_home_bet" ? (
                        <td
                          rowSpan="3"
                          title="Open all odds"
                          className="text-center align-middle p-0 fontSizeDefaultDevice"
                        >
                          {
                            <div className="w-100 d-flex justify-content-end">
                              <div
                                id="openAllodds"
                                className="rounded-start py-4 greenArbitragem openAllodds"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleClick(value);
                                }}
                              >
                                <button>
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 6 16"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                    }}
                                  >
                                    <path
                                      fillRule="evenodd"
                                      fill={`${statusMode ? "#0d0d0d" : "#FFF"
                                        }`}
                                      d="M0 14l6-6-6-6v12z"
                                    ></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          }
                        </td>
                      ) : null}
                    </tr>
                  );
                } else {
                  return null;
                }
              })}

              <tr className="displayMobile"></tr>

              <tr>
                <td colSpan="6" className="text-end">
                  <div className="">
                    <p
                      className={`text-decoration-none pe-1 px-0 legenda-2 fontSizeDefaultDevice displayMobile`}
                      style={{ visibility: "hidden" }}
                    >
                      XXXXXXXXXXXXXXXXXXXX
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      ) : (
        <>
          <style>{`
                body
                #containerFindBet{
                    margin:0;
                    padding:0;
                    background:#262626;
                    height: 600px
                  }
                  .ring{
                    line-height: 1.5!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position:relative;
                    top:50%;
                    left:50%;
                    transform:translate(-50%,-50%);
                    width:380px;
                    height:380px;
                    background:transparent;
                    border:3px solid #3c3c3c;
                    border-radius:50%;
                    text-align:center;
                    line-height:150px;
                    font-family:sans-serif;
                    font-size:20px;
                    color:#fff000;
                    letter-spacing:4px;
                    text-transform:uppercase;
                    text-shadow:0 0 10px #fff000;
                    box-shadow:0 0 20px rgba(0,0,0,.5);
                  }
                  .ring:before{
                    content:'';
                    position:absolute;
                    top:-3px;
                    left:-3px;
                    width:100%;
                    height:100%;
                    border:3px solid transparent;
                    border-top:3px solid #fff000;
                    border-right:3px solid #fff000;
                    border-radius:50%;
                    animation:animateC 2s linear infinite;
                  }
                  #spinnerAnimation{
                    display:block;
                    position:absolute;
                    top:calc(50% - 2px);
                    left:50%;
                    width:50%;
                    height:4px;
                    background:transparent;
                    transform-origin:left;
                    animation:animate 2s linear infinite;
                  }
                  #spinnerAnimation:before{
                    content:'';
                    position:absolute;
                    width:16px;
                    height:16px;
                    border-radius:50%;
                    background:#fff000;
                    top:-6px;
                    right:-8px;
                    box-shadow:0 0 20px #fff000;
                  }
                  @keyframes animateC{
                    0%
                    {
                      transform:rotate(0deg);
                    }
                    100%
                    {
                      transform:rotate(360deg);
                    }
                  }
                  @keyframes animate{
                    0%
                    {
                      transform:rotate(45deg);
                    }
                    100%
                    {
                      transform:rotate(405deg);
                    }
                  }
                `}</style>

          <div id="containerFindBet">
            <div className="ring">
              Procurando
              <br />
              por
              <br />
              apostas
              <span id="spinnerAnimation"></span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default HideSurebet;
