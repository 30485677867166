import Carousel from 'react-bootstrap/Carousel';


function CarouselHP() {
    const imghomepage = `${process.env.PUBLIC_URL}/images/PageInicial.jpg`;
    const imghomepage1 = `${process.env.PUBLIC_URL}/images/PageInicial1.jpg`;
    const imghomepage2 = `${process.env.PUBLIC_URL}/images/PageInicial2.jpg`;
  return (
    <Carousel 
    indicators={false}>
      <Carousel.Item interval={4000}>
      <a
            href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
              src={imghomepage1}
              alt="HomePage"
            />
          </a>
       
      </Carousel.Item>
      <Carousel.Item interval={4000}>
      <a
            href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
              src={imghomepage}
              alt="HomePage"
            />
          </a>
       
      </Carousel.Item>
      <Carousel.Item interval={4000}>
      <a
            href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
              src={imghomepage2}
              alt="HomePage"
            />
          </a>
        
      </Carousel.Item>
    </Carousel>
          
  );
}

export default CarouselHP;