import '../assets/css/page-present-extension.css'
import videoRedirectic from '../assets/video/redirect.mp4';
import IconChrome from '../assets/svg/chrome.svg';
import IconFirefox from '../assets/svg/firefox.svg';
import BGimg from '../assets/img/background_page_extension.png';

function MainContainerShowExtension() {


    
    return (
        <>
            <style>{`
                body{
                    background-image: url("${BGimg}");
                    background-position: center;
                    background-size: cover;
                }
                `}
            </style>
            <div className='d-flex flex-column align-items-center mt-5'>
                <h1 className='fs-h1-page-present-extension text-light text-center'>
                    APRENDA A INSTALAR A EXTENSÃO <br />
                    E ABRA OS JOGOS AUTOMATICAMENTE
                </h1>


                <iframe className='video-page-present-extension mt-3' src='https://www.youtube.com/embed/burS7UK0eDU?autoplay=1&mute=1&showinfo=0&showsearch=0&iv_load_policy=3&cc_load_policy=1&fs=1'
                frameborder="0"
                allow='autoplay'
                allowfullscreen>
                    
                </iframe>

                <div className='d-flex container-buttons-page-present-extension justify-content-around'>
                    <a href='/files/arbitragem.bet_ext_chrome.zip' className='fs-container-btn-download-page-present-extension text-light text-center mt-3'>
                        <img className='icon-browser-page-present-extension' src={IconChrome} alt="A beautiful landscape" />

                        <div>GOOGLE CHROME</div>
                        <button className='btn-download-page-present-extension'>BAIXAR EXTENSÃO</button>
                    </a>

                    <a href='/files/arbitragem.bet_ext_firefox.zip' className='fs-container-btn-download-page-present-extension text-light text-center mt-3'>
                        <img className='icon-browser-page-present-extension' src={IconFirefox} alt="A beautiful landscape" />
                        <div>FIREFOX</div>
                        <button className='btn-download-page-present-extension'>BAIXAR EXTENSÃO</button>
                    </a>
                </div>
            </div>
        </>
    );
}


export default MainContainerShowExtension;