import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "react-modal";
import { Routes, Route } from "react-router-dom";
import "../assets/css/HeaderWebsite.css";
import AuthenticationForm from "./AuthenticationForm";
import RedeSociaisComponent from "./RedeSociaisComponent";
import HomePage from "./HomePage";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../assets/css/SubNavbar.css";
import IconDollar from '../assets/img/cifrao.png';

Modal.setAppElement("#root");

const SubNavbar = (props) => {
  const {
    statusMode,
    setStatusMode,
    onLogout,
    isLoggedIn,
    showLogoutButton,
    handleLoginSuccess,
  } = props;

  const wpplogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-whatsapp-96.png`;
  const instalogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-instagram-96.png`;
  const youtlogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-reproduzir-youtube-96.png`;
  const wpplogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-whatsapp-96-preto.png`;
  const instalogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-instagram-96-preto.png`;
  const youtlogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-reproduzir-youtube-96-preto.png`;
  const logobranca = `${process.env.PUBLIC_URL}/LOGOBRANCO.png`;
  const imgbghome = `${process.env.PUBLIC_URL}/images/bghome.png`;

  // Inicializa a variável com true para mostrar o cabeçalho
  const [showHeader, setShowHeader] = useState(true);
  const { pathname } = useLocation();

  const shouldShowLogoutButton = () => {
    const hash = window.location.hash;

    // Verifica se a rota atual é exatamente "/" ou não possui hash (caso padrão)
    if (hash === "" || hash === "/") {
      return true;
    }

    // Verifica se a rota atual contém "/calculator/"
    if (hash.includes("/calculator/")) {
      return false;
    }

    // Obtenha a rota atual
    const currentPath = window.location.pathname;

    // Registre a rota atual no console

    // Verifique se a rota atual é exatamente "/"
    if (currentPath === "/") {
      return true;
    }

    // Verifique se a rota atual contém "/calculator/"
    if (currentPath.includes("/calculator/")) {
      return false;
    }

    // Em todos os outros casos, mostra o botão de logout

    return true;
  };

  const [volume, setVolume] = useState(0.5);
  // Função que verifica se a rota é "/logar"
  const isLogar = () => {
    const hash = window.location.hash;
    return hash === "/logar" || hash === "/curso";
  };

  // Função para alternar a exibição do cabeçalho
  const toggleHeader = () => {
    setShowHeader(!isLogar());
  };
  const changeStatusMode = (oldStatusMode) => {
    setStatusMode(!oldStatusMode);
   
    window.localStorage.setItem("statusMode", JSON.stringify(!oldStatusMode));
    const dataToJson = JSON.stringify(!oldStatusMode);
    localStorage.setItem("statusMode", dataToJson);
    !oldStatusMode
      ? (document.body.style.backgroundColor = "#0d0d0d")
      : (document.body.style.backgroundColor = "");
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;

      // Verifica se a URL atual não é "/logar"
      if (hash !== "/logar" || hash !== "/apostas") {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    // Registre o ouvinte de evento para mudanças de hash
    window.addEventListener("hashchange", handleHashChange);

    // Certifique-se de chamar handleHashChange para verificar a URL inicial
    handleHashChange();

    // Remova o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  return (
    <div
      className="App Initial-Control-Page"
      style={{ backgroundImage: `url(${imgbghome})` }}
    >
          <style>
      {`
      .style-justify-beteween .container-fluid{
          justify-content: space-between !important;
        }
      `}
    </style>
      {showHeader === false ? (
        <div>
          <AuthenticationForm
            statusMode={statusMode}
            onLoginSuccess={handleLoginSuccess}
            showHeader={showHeader}
            setShowHeader={setShowHeader}
          />
        </div>
      ) : (
        <div>
          <>
            {["xl"].map((expand) => (
              <Navbar
                key={expand}
                expand={expand}
                className={`bg-body-tertiary mb-3 header d-flex justify-content-between align-items-center navbar navbar-expand-xl navbar-dark mb-3 ResponsivHeader style-justify-beteween ${
                  statusMode ? "dark-mode" : ""
                }`}
              >
                <Container fluid>
                  <Navbar.Brand href="#" style={{ margin: "0" }}>
                    <div className=" p-2 fw-semibold d-flex align-items-center">
                      <a
                        className={`text-decoration-none fs-5${
                          statusMode ? "textDark" : "text-light"
                        }`}
                        href="/"
                        style={{ margin: "0" }}
                      >
                        <img
                          src={logobranca}
                          alt="Logo"
                          style={{ height: "55px", width: "auto" }}
                          className="imgfullhd"
                        />
                      </a>
                    </div>
                  </Navbar.Brand>
                  <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                    className="margin20px"
                    style={{ padding: "5px",height:"4rem",width:"5rem" }}
                  />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-${expand}`}
                      >
                        <a
                          className={`text-decoration-none fs-5 me-3 ${
                            statusMode ? "textDark" : "text-light"
                          }`}
                          href="/"
                        >
                          <img
                            src={logobranca}
                            alt="Logo"
                            style={{ height: "55px", width: "auto" }}
                            className="imgfullhd"
                          />
                        </a>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      {/* Inicio do itens do social*/}
                      <div className="social">
                        <a
                          href="https://www.instagram.com/arbitragem.bet/"
                          target="_blank"
                          rel="noreferrer"
                          className="social-link"
                        >
                          <img
                            src={instalogo}
                            alt="Instagram"
                            className="social-icon insta"
                          />
                        </a>
                        <div className="space-icon"></div>
                        <a
                          href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
                          target="_blank"
                          rel="noreferrer"
                          className="social-link"
                        >
                          <img
                            src={youtlogo}
                            alt="YouTube"
                            className="social-icon youtube"
                          />
                        </a>
                        <div className="space-icon"></div>
                        <a
                          href="https://wa.me/message/SKLE6BOPTNTYC1"
                          target="_blank"
                          rel="noreferrer"
                          className="social-link"
                        >
                          <img
                            src={wpplogo}
                            alt="WhatsApp"
                            className="social-icon"
                          />
                        </a>
                      </div>
                      {/* Fim do itens do social*/}
                      <div className="menu mudanca-menu">
                        <ul className={`menu-links text-light style-menu-home`}>
                          <NavLink to="/afiliado">
                            <li className="nav-link">
                              <span className="navlink">
                                <a
                                  href="https://forms.gle/8WLurjnnHDjq4jY27"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="linknavbar d-flex align-items-center"
                                >
                                  <b className={` text-light `}>AFILIADOS</b>
                                </a>
                              </span>
                            </li>
                          </NavLink>
                          <a
                            href="https://api.whatsapp.com/send?phone=5511978533587&text=Gostaria%20de%20saber%20mais%20detalhes%20sobre%20os%207%20dias%20gr%C3%A1tis%21"
                            //Gostaria de saber mais detalhes sobre os 7 dias grátis!
                            target="_blank"
                            rel="noreferrer"
                            className="linknavbar"
                          >
                            <li className="nav-link">
                              <span className="navlink">
                                <a className="linknavbar d-flex align-items-center">
                                  <b className={` text-light `}>
                                    7 DIAS GRÁTIS
                                  </b>
                                </a>
                              </span>
                            </li>
                          </a>
                          <NavLink to="/curso">
                            <li className="nav-link">
                              <span className="navlink">
                                <a className="linknavbar d-flex align-items-center">
                                  <b className={` text-light `}>CURSO</b>
                                </a>
                              </span>
                            </li>
                          </NavLink>

                          <NavLink 
                          to="https://t.me/arbbonusfree"
                          as="a"
                          target="_blank"
                          rel="noopener noreferrer">
                            <li className="nav-link style-bonus-menu-home me-0">
                              <span className="navlink">
                                <a className="linknavbar d-flex align-items-center">
                                  <b className={` text-light `}>BÔNUS</b>
                                  <img className="icon-bonus" src={IconDollar} alt="" srcset="" />
                                </a>
                              </span>
                            </li>
                          </NavLink>
                        </ul>
                      </div>
                      <Navbar.Collapse className="justify-content-end btn-apostas-home style-container-btn-login">
                        <Navbar.Text>
                          <NavLink to="/logar">
                            <button style={{width:"125px"}}>
                              <li className="nav-link nav-link-special">
                                <span className="navlink">
                                  <a>
                                    <b className={` text-light stylefontfull `}>
                                      APOSTAS
                                    </b>
                                  </a>
                                </span>
                              </li>
                            </button>
                          </NavLink>
                        </Navbar.Text>
                      </Navbar.Collapse>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            ))}
          </>

          <div className="container-center">
            <Routes>
              <Route path={"/"} element={<HomePage />} s />

              <Route
                path={"/redesociais"}
                element={<RedeSociaisComponent statusMode={statusMode} />}
                s
              />
             
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubNavbar;
