import React,{useEffect} from "react";
//import CalculatorContentMobile from "./CalculatorContentMobile";
import CalculatorContent from "./CalculatorContent";



function Calculator(props) {
  const { statusMode } = props;
  
  useEffect(() => {
    if (statusMode) {
      
      document.documentElement.className = "dark-theme";
      document.documentElement.setAttribute('data-bs-theme', 'dark-theme');
    } else {
      document.documentElement.className = "light-theme";
      document.documentElement.setAttribute('data-bs-theme', 'light-theme');
    }
    localStorage.setItem("theme", statusMode);
  }, [statusMode]);
  return (
    
      <CalculatorContent 
      statusMode={statusMode} 
      />
    
  );
}

export default Calculator;
