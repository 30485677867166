import "../assets/css/calculator.css";
import { Parser } from "html-to-react";
import { Tooltip } from 'react-tooltip'
import { IconPlus, IconLess, IconInterrogation } from "../global/ExternalIcon";
import 'react-tooltip/dist/react-tooltip.css'
import { NameBetHomeRenamed, styleDataMarket, partInfoOddSurebetJSON } from "../global/GlobalVariables";
import { finderRealOdd, changeValueAllAdditionalInfoSurebet, setStateAllAdditionalInfoSurebet, eventPressButtonUpDownIncreaseDecreaseValue } from "./functions/FunctionsCalculatorContent";




const orderloop = [
  "first_home_bet",
  "second_home_bet",
  "third_home_bet",
  "fourth_home_bet",
];


function EmptyCalculatorMobile(props) {
  const {
    statusMode,
    data,
    profit,
    infoCalcFrontEnd,
    setInfoCalcFrontEntJSON,
    changeOdds,
    qtMoney,
    odds,
    profitOdds,
    statusRounded,
    valueRounded,
    eventMouseDownDecreaseValueCalculator,
    handleMouseUpOrLeave,
    eventMouseDownIncreaseValueCalculator,
    changeBetValue,
    qtMoneyBet,
    adjusterValueSurebet,
    CalcPay,
    setCalcPay,
    changeQtMoney,
    setStatusRounded,
    surebetCalculate,
    getCashFixed,    
    setValueRounded,
  } = props;
  return (
    <>


      {data.error !== undefined ? (

        <div
          className="text-center d-flex flex-column justify-content-center"
          style={{ height: "calc(100vh - 78px)", background: "#CCC" }}
        >
          <h2 className="h1 fw-bold">Apostas não encontrada</h2>
          <p className="fs-4">
            Sua aposta não está mais disponível no momento.
            <br />
            Por favor tente mais tarde.
          </p>
        </div>
      ) : Object.keys(data).length > 0 ? (
        <>
          <style>
            {`
          @media (max-width: 360px) {

            .card{
              min-width: 100%!important
            }
          }
          `}
          </style>
          <div className="d-flex justify-content-center">
            <div
              className={`card m-3 shadow ${statusMode ? "bgDark borderColor textGray shadowDark" : ""
                }`}
              style={{ marginTop: "70px" }}
            >
              <div className="">
                <div className="d-flex justify-content-between align-items-center info-event-mobile">
                  <div>
                    <h5 className="card-title m-0 title-event-calculator-mobile fw-bold">
                      Calculadora de arbitragens
                    </h5>
                    <h6 className="mb-3 sub-title-event-calculator-mobile fw-bold text-secondary">
                      Calcule suas apostas aqui

                    </h6>
                  </div>
                  <p
                    className={`${profit >= 0 ? "text-success" : "text-danger"
                      } profit-calculator-mobile fw-bold`}
                    style={{ color: "black" }}
                  >
                    {profit >= 0 ? "+" : ""}
                    {profit.toFixed(2)}%
                  </p>
                </div>


                <div className="container-panel-calculator-mobile border border-color-dark-mode">
                  <div className="fw-bold text-center border-bottom border-color-dark-mode">
                    Chance
                  </div>

                  <div className="d-flex">
                    {
                      orderloop.map((order, index) => {
                        if (order in data) {

                          return (
                            <div className="w-100" key={index}>
                              <div className={`box-chance-calculator-mobile ${index % 2 == 0 ? 'border-end' : 'border-start'} border-bottom border-color-dark-mode`}>
                                <div>
                                  <p className="mt-bet-home-calculator-mobile fs-home-calculator-mobile text-center fw-bold">
                                    {
                                      data[order]["name_bet_home"]
                                    }
                                  </p>
                                </div>
                               

                                <div className="form-odd-calculator-mobile d-flex justify-content-center">
                                  <input
                                    type="text"
                                    class="form-control mx-2 text-center input-calculator-mobile"
                                    value={infoCalcFrontEnd['odds'][index]}


                                    onChange={(event) => {
                                      const { value } = event.target;

                                      let valueModified = Number(value.replaceAll(',', '.'));

                                      if (!isNaN(valueModified)) {

                                        infoCalcFrontEnd['odds'][index] = value;
                                        infoCalcFrontEnd['allStatusComma']['odds'][index] = value.includes(',');
                                        setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));
                                        changeOdds(
                                          valueModified,
                                          index,
                                          qtMoney,
                                          odds,
                                          profitOdds,
                                          statusRounded,
                                          valueRounded
                                        );
                                      }
                                    }}
                                    onKeyDown={(event) => {



                                      let paramFunction = function (paramState) {
                                        infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                                        setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                                        changeOdds(
                                          paramState,
                                          index,
                                          qtMoney,
                                          odds,
                                          profitOdds,
                                          statusRounded,
                                          valueRounded
                                        );
                                      }

                                      eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction, 0.01);
                                    }
                                    }
                                  />
                                </div>

                                <div className="w-100 d-flex justify-content-around align-items-center btn-icon-calculator-mobile">
                                  <button
                                    onMouseDown={() => {
                                      let paramFunction = function (paramState) {
                                        infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                                        setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));
                                        changeOdds(
                                          paramState,
                                          index,
                                          qtMoney,
                                          odds,
                                          profitOdds,
                                          statusRounded,
                                          valueRounded
                                        );
                                      }
                                      eventMouseDownDecreaseValueCalculator(Number(odds[index]).toFixed(2), paramFunction, 0.01);
                                    }}
                                    onMouseUp={handleMouseUpOrLeave}
                                    onMouseLeave={handleMouseUpOrLeave}
                                  >
                                    <IconLess />
                                  </button>

                                  <button onMouseDown={() => {
                                    let paramFunction = function (paramState) {
                                      infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                                      setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));


                                      changeOdds(
                                        paramState,
                                        index,
                                        qtMoney,
                                        odds,
                                        profitOdds,
                                        statusRounded,
                                        valueRounded
                                      );
                                    }
                                    eventMouseDownIncreaseValueCalculator(Number(odds[index]).toFixed(2), paramFunction, 0.01);
                                  }}
                                    onMouseUp={handleMouseUpOrLeave}
                                    onMouseLeave={handleMouseUpOrLeave}>
                                    <IconPlus />
                                  </button>


                                </div>
                              </div>
                            </div>
                          )
                        }
                        return "";
                      })
                    }
                  </div>


                  <div className="fw-bold text-center border-bottom border-top rounded-top border-color-dark-mode">
                    Apostas
                  </div>
                  <div className="d-flex">
                    {
                      orderloop.map((order, index) => {
                        if (order in data) {

                          return (
                            <div className="w-100" key={index}>
                              <div className={`box-stake-calculator-mobile`}>
                                <div>
                                  <p className="mt-profit-calculator-mobile text-center fw-bold">
                                    Valor {index + 1}
                                  </p>
                                </div>


                                <div className="mt-1 d-flex justify-content-center">
                                  <input
                                    type="text"
                                    value={infoCalcFrontEnd['qtMoneyBet'][index]}
                                    class="form-control mx-2 text-center input-calculator-mobile"

                                    onChange={(event) => {
                                      const { value } = event.target;

                                      let valueModified = Number(value.replaceAll(',', '.'));

                                      if (!isNaN(valueModified)) {
                                        infoCalcFrontEnd['qtMoneyBet'][index] = value;
                                        infoCalcFrontEnd['allStatusComma']['qtMoneyBet'][index] = value.includes(',');
                                        setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                                        changeBetValue(
                                          valueModified,
                                          index,
                                          qtMoney,
                                          odds,
                                          qtMoneyBet
                                        );
                                      }


                                    }
                                    }
                                    onKeyDown={(event) => {



                                      let paramFunction = function (paramState) {
                                        changeBetValue(
                                          parseInt(paramState),
                                          index,
                                          qtMoney,
                                          odds,
                                          qtMoneyBet
                                        );
                                      }

                                      eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction);
                                    }
                                    }
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div className="div-input-radio-calculator-mobile">
                                    <input
                                      class="form-check-input d-block fs-input-radio-calculator-mobile position-relative m-0 mt-2"
                                      type="radio"
                                      onChange={() => { adjusterValueSurebet(index) }} checked={CalcPay === index ? true : false}
                                    />
                                  </div>
                                </div>

                              </div>
                            </div>
                          )
                        }
                        return "";
                      })
                    }
                  </div>

                  <div className="fw-bold text-center">
                    Valor Total
                  </div>

                  <div className="d-flex justify-content-center align-items-center pb-4 border-bottom border-color-dark-mode">

                    <input
                      type="text"
                      class="form-control text-center w-50 input-calculator-mobile"
                      value={infoCalcFrontEnd['qtMoney']}
                      onChange={(event) => {

                        const { value } = event.target;

                        let valueModified = Number(value.replaceAll(',', '.'));

                        if (!isNaN(valueModified)) {
                          infoCalcFrontEnd['qtMoney'] = value;
                          infoCalcFrontEnd['allStatusComma']['qtMoney'] = value.includes(',');

                          setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                          changeQtMoney(
                            valueModified,
                            odds,
                            profitOdds,
                            statusRounded,
                            valueRounded,
                            -999,
                          );
                        }

                      }}
                      onKeyDown={(event) => {



                        let paramFunction = function (paramState) {
                          changeQtMoney(
                            parseInt(paramState),
                            odds,
                            profitOdds,
                            statusRounded,
                            valueRounded,
                            -999,
                          );
                        }

                        eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction);
                      }
                      }
                    />
                    <input class="form-check-input d-block fs-input-radio-calculator-mobile position-relative m-0 ms-1" type="radio" checked={CalcPay === -999 ? true : false} onChange={() => { setCalcPay(-999) }} />

                  </div>
                  <div className="fw-bold text-center">
                    Lucro
                  </div>
                  <div className="d-flex border-bottom border-color-dark-mode">
                    {
                      orderloop.map((order, index) => {
                        if (order in data) {

                          return (
                            <div className="w-100" key={index}>
                              <div className={`text-center fw-bold my-3`}>
                                {profitOdds[index].toFixed(2).length > 9 ? `${profitOdds[index].toFixed(2).slice(0, 9)}...` : profitOdds[index].toFixed(2)}

                              </div>
                            </div>
                          )
                        }
                        return "";
                      })
                    }
                  </div>

                  <div class="form-check w-100 d-flex justify-content-center fw-bold py-2">
                    <input
                      class="form-check-input position-relative me-2"
                      type="checkbox"
                      id="round-surebet"
                      checked={statusRounded}
                      onChange={(event) => {
                        const { value } = event.target;

                        // Verifica se value é uma string e não está vazio
                        if (typeof value === "string" && value.trim() !== "") {
                          setStatusRounded(!statusRounded);
                          surebetCalculate(
                            getCashFixed(CalcPay),
                            odds,
                            profitOdds,
                            !statusRounded,
                            valueRounded,
                            CalcPay,                            
                          );
                          localStorage.setItem(
                            "roundedIsActive",
                            JSON.stringify(!statusRounded)
                          );
                        }
                      }}
                    />
                    <label class="form-check-label p-0" for="round-surebet">
                      Arredondar apostas até:
                    </label>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mt-3 mb-5">

                    <input
                      type="text"
                      class="form-control text-center w-50 input-calculator-mobile"
                      placeholder="0.00"
                      value={valueRounded}

                      onChange={(event) => {
                        const { value } = event.target;



                        let newValueModified = Number(value.replace(',', '.'));



                        // Verifica se value é uma string e não está vazio
                        if (!isNaN(newValueModified)) {

                          setValueRounded(value);
                          surebetCalculate(
                            getCashFixed(CalcPay),
                            odds,
                            profitOdds,
                            statusRounded,
                            newValueModified,
                            CalcPay,
                            
                          );

                          localStorage.setItem(
                            "roundedValue",
                            JSON.stringify(newValueModified)
                          );
                        }
                      }}
                      disabled={!statusRounded}
                    />

                  </div>



                </div>





              </div>
            </div>
          </div>
        </>
      ) : (
        <section className="talign-center">
          <strong className="text-light">
            {" "}
            <h2>ARBITRAGEM.BET</h2>{" "}
          </strong>
          <span className="loader"></span>
          <p className="text-light fs-3">carregando...</p>
        </section>
      )}
    </>
  );
}

export default EmptyCalculatorMobile;
