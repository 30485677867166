import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import "../assets/css/Footer.css";
import { Modal, Form } from "react-bootstrap";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
const logobranca = `${process.env.PUBLIC_URL}/LOGOBRANCO.png`;

export default function Footer() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  var checkbox = document.getElementById("termos");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div id="footer_card">
        <div className="log_card img-footer">
          <h6>
            <img src={logobranca} alt="Logo" />
          </h6>
          <h2>
            <p className="text-direitos">© Todos os direitos reservados.</p>
          </h2>
        </div>
        <div className="footer-mobile">
          <div className="home_card">
            <h6 className=" fw-bold">Início</h6>
            <p>
              <a href="/" className="text-reset">
                Home
              </a>
            </p>
            <p>
              <a href="/afiliado" className="text-reset">
                Afiliados
              </a>
            </p>
            <p>
              <a href="/curso" className="text-reset">
                Cursos
              </a>
            </p>
          </div>
          <div className="sobrenos_card">
            <h6 className=" fw-bold">Sobre nós</h6>
            <p>
              <a href="/quesomos" className="text-reset">
                Quem somos
              </a>
            </p>
            
          </div>
          <div className="termos_card">
            <h6>
              <b>Políticas de Privacidade</b>
            </h6>
            <p>
              <a href="/termoscondicoes" className="text-reset">
                Termos e Condições
              </a>
            </p>
          </div>
          <div className="contato_card">
            <h6 className=" fw-bold">Contatos</h6>
            <div className="footer-contato-card">
              <p>
                <MDBIcon icon="youtube" />
                <a
                  href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  @arbitragembet
                </a>
              </p>
              <p>
                <MDBIcon icon="instagram" />
                <a
                  href="https://www.instagram.com/arbitragem.bet/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  @arbitragem.bet
                </a>
              </p>
              <p>
                <MDBIcon icon="phone" />{" "}
                <Button
                  variant="success"
                  href="https://wa.me/message/SKLE6BOPTNTYC1"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: "10px" }}
                >
                  CONTATO
                </Button>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <MDBFooter bgColor="dark" className="bg-dark text-center text-white">
        <section className="footer-page"></section>
        <div
          className="text-center text-footer-bottom"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          © 2023 ARBITRAGEM.BET
        </div>
      </MDBFooter>

      <div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header className={`modal-termos-codicoes-title`} closeButton>
            <Modal.Title>
              <b>Termos e condições</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={`modal-termos-codicoes-body  `}>
            <p>
              <h5>
                <b>1 - Disposições gerais</b>
              </h5>
            </p>
            <p>
              <b>1.1</b> - O contrato é feito entre nós da “Administração” do
              site <b>(Arbitragem.bet)</b> provendo o “Serviço” de fornecer
              informações para a outra parte interessada, denominada "Usuário".
            </p>
            <p>
              <b>1.2</b> - As disposições do contrato estabelecem as condições
              de fornecimento das informações para o Usuário pelo serviço.
            </p>
            <p>
              <b>1.3</b> - Ao aceitar este acordo, o Usuário compromete-se a
              cumprir suas disposições, e confirma ter ao menos 18 anos de
              idade. Em caso de desacordo do Usuário com qualquer uma das
              condições do acordo previsto nos termos, o Usuário compromete-se a
              não utilizar o serviço.
            </p>
            <p>&nbsp;</p>
            <p>
              <h5>
                <b>2 - Informações fornecidas pelo Serviço</b>
              </h5>
            </p>
            <p>
              <b>2.1</b> - O site <b>(Arbitragem.bet)</b> irá escanear
              automaticamente todos os mercados das casas de apostas disponíveis
              na plataforma e fornecerá informações sobre os dados encontrados
              para que o Usuário possa analisar. O Serviço não é um instrumento
              financeiro e não presta nenhum tipo de serviço de gestão de
              fundos. O serviço não é uma casa de apostas ou um jogo, apenas
              fornece informações.
            </p>
            <p>
              <b>2.2</b> - A quantidade de informações fornecidas pelo Serviço
              pode variar ao longo do tempo. A Administração reserva-se no
              direito de adicionar ou remover qualquer dado que julgar adequado.
            </p>
            <p>
              <b>2.3</b> - A quantidade de casas de apostas disponíveis pode
              variar ao longo do tempo. A Administração não se responsabiliza
              pelas informações fornecidas pelas casas de apostas ao que diz
              respeito sobre suas linhas e mercados. O Serviço apenas colhe os
              dados que serão providos pelos mesmos. Qualquer erro nas linhas
              deverá ser reportado para que as medidas cabíveis sejam tomadas.
            </p>
            <p>
              <b>2.4</b> - A informação fornecida pelo Serviço é disponibilizada
              ao Usuário mediante pagamento previamente selecionado na escolha
              do seu Plano.[LINK DA PAGINA DOS PLANOS]
            </p>
            <p>
              <b>2.5</b> - O Usuário faz uso das informações fornecidas por sua
              própria conta e risco. Nenhuma informação fornecida pelo Serviço
              pode ser considerada instrução para utilização dos bens em
              dinheiros do Usuário.
            </p>
            <p>
              <b>2.6</b> - As informações são fornecidas para o usuário como
              estão. O Usuário está ciente dos riscos associados à possíveis
              discrepâncias entre informações prestadas e a realidade, e desiste
              de quaisquer reclamações relacionadas com a reparação de eventuais
              prejuízos que sejam direta ou indiretamente ocasionados pela
              utilização da informação prestada.
            </p>
            <p>
              <b>2.7</b> - A Administração não se responsabiliza pelas
              consequências do uso de quaisquer informações fornecidas pelo
              Serviço.
            </p>
            <p>
              <b>2.8</b> - As informações fornecidas ao Usuário são apenas para
              o uso pessoa, independente do plano tarifário selecionado. O
              Usuário se compromete a não divulgar quaisquer informações
              fornecidas pelo Serviço sem autorização da Administração.
            </p>
            <p>
              <b>2.9</b> - O Usuário se compromete a não utilizar quaisquer
              tipos de robôs que façam leitura em larga escala das informações
              fornecidas pelo Serviço. Sendo passível de banimento permanente do
              site <b>(Arbitragem.bet)</b> por meio de seus dados de IP.
            </p>
            <p>&nbsp;</p>
            <p>
              <h5>
                <b>3 - Adesão aos Planos</b>
              </h5>
            </p>
            <p>
              <b>3.1</b> - A adesão aos planos é assegurada pela Administração
              do Serviço de acordo com um dos planos sugeridos.
            </p>
            <p>
              <b>3.2</b> - O período de validade da adesão é indicado em dias
              corridos.
            </p>
            <p>
              <b>3.3</b> - A adesão a um plano é considerada ativa assim que a
              Administração confirmar o recebimento do valor acordado pelo
              Usuário por um dos meios de pagamento disponíveis.
            </p>
            <p>
              <b>3.4</b> - Ao selecionar um plano, o Usuário é obrigado a ler as
              disposições gerais deste contrato. A adesão não é fornecida em
              caso de desacordo do Usuário com qualquer artigo deste contrato.
            </p>
            <p>
              <b>3.5</b> - O Usuário tem o direito de cancelar a adesão do plano
              pago e/ou trocá-lo por um mais barato dentro do período de 7 dias,
              à contar da data em que o mesmo for considerado pago pela
              Administração.
            </p>
            <p>
              <b>3.6</b> - O Usuário tem o direito de alterar o plano de adesão
              ao Serviço por outro de maior valor antes do término do plano
              presente, fazendo assim somente o envio do valor restante para
              adesão do novo plano.
            </p>
            <p>
              <b>3.7</b> - A Administração se reversa no direito de limitar o
              acesso do Usuário ao Serviço em caso de descumprimento do contrato
              por parte do Usuário. Em caso de desconfiança do Usuário quanto à
              violação de quaisquer dos artigos presentes neste contrato, o
              acesso do Usuário ao Serviço pode ser bloqueado até ser feita
              apuração dos fatos sem a devolução dos valores pagos previamente.
            </p>
            <p>&nbsp;</p>
            <p>
              <h5>
                <b>4 - Responsabilidades do Usuário</b>
              </h5>
            </p>
            <p>
              <b>4.1</b> - O Usuário se compromete a verificar a veracidade das
              informações prestadas imediatamente antes de qualquer eventual
              utilização das mesmas. Como dito no artigo 2.3, as informações são
              fornecidas pelas casas de apostas e qualquer divergência não é
              responsabilidade do Serviço.
            </p>
            <p>
              <b>4.2</b> - O Usuário assume todos os riscos que envolvam a
              utilização das informações fornecidas pelo Serviço, sendo elas
              possíveis lucros e perdas.
            </p>
            <p>&nbsp;</p>
            <p>
              <h5>
                <b>5 - Responsabilidades da Administração</b>
              </h5>
            </p>
            <p>
              <b>5.1</b> - A Administração se compromete em manter o Serviço em
              funcionamento. A ordem de funcionamento do Serviço é de pelo menos
              70% dos scanners fazendo a leitura das casas de apostas que estão
              disponíveis no site <b>(Arbitragem.bet)</b>.
            </p>
            <p>
              <b>5.2</b> - Em caso de impossibilidade de funcionamento do
              Serviço por algum tempo, a Administração se compromete a prorrogar
              a validade da adesão do plano.
            </p>
            <p>
              <b>5.3</b> - A Administração aceita reclamações sobre o
              funcionamento do Serviço. As reclamações serão levadas em
              consideração e se a Administração entender que há necessidade,
              serão executadas.
            </p>
            <p>
              <b>5.4</b> - A Administração não garante a veracidade das
              informações fornecidas pelo Serviço, porém, se esforça para
              melhorar e aumentar a qualidade dos dados fornecidos juntamente
              com as casas de apostas.
            </p>
            <p>
              <b>5.5</b> - A Administração não aceita quaisquer reclamações
              relacionadas à compensação de eventuais prejuízos que sejam direta
              ou indiretamente ocasionados pela utilização do Serviço.
            </p>
            <p>&nbsp;</p>
            <p>
              <h5>
                <b>6 - Alterações neste contrato</b>
              </h5>
            </p>
            <p>
              <b>6.1</b> - A Administração reserva-se no direito de alterar
              qualquer artigo disposto no contrato de utilização. Qualquer
              alteração feita será informada aos Usuários.
            </p>
            <p>
              <b>6.2</b> - A continuação ao uso do Serviço após ser informado
              sobre as alterações no contrato será considerado como aceitação
              dessas alterações.
            </p>
            <p>&nbsp;</p>
          </Modal.Body>
          <Modal.Footer>
            <p>
              <input type="checkbox" id="termos" />
              Aceito os Termos e Condições
            </p>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
