
import { useSearchParams } from 'react-router-dom';
import Redirect from '../pages/Redirect';

import ManagerDataGameExtension from '../pages/ManagerDataGameExtension';

const ExtensionBrowser = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    const standalone = searchParams.get('standalone');
    const dataArb = searchParams.get('data-arb');

    const dataMakers = searchParams.get('data-markers');
    const dataLinks = searchParams.get('data-links');
    let dataArbDecoded = typeof dataArb === 'string' ? JSON.parse(dataArb) : false;
    return (
        <>

            {
                standalone !== null && dataArb !== null ?
                    <ManagerDataGameExtension
                        dataArb={dataArbDecoded}
                    />
                    : dataMakers !== null && dataLinks !== null && dataArb !== null ?
                        <Redirect
                            dataMakers={dataMakers}
                            dataLinks={dataLinks}
                            dataArb={dataArbDecoded}
                        />
                        :
                        <div className='w-100 bg-success d-flex justify-content-center align-items-center' style={{height: '100vh'}}>   
                            <h1>Página não encontrada</h1>
                        </div>
            }
        </>
    );
};

export default ExtensionBrowser;