import { StatusScreen } from "@mercadopago/sdk-react";

const StatusUpdate = (props) => {
    const {preferenceID} = props;

    console.log("preferenceID",preferenceID)
    console.log("localStorage preferenceID",JSON.parse(localStorage.getItem("preferenceID")))
  const initialization = {
    paymentId: `${JSON.parse(localStorage.getItem("preferenceID"))}`, // id do pagamento a ser mostrado
  };
  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
  };
  const onReady = async () => {
    /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
  };
  console.log("initialization",initialization)
  // Aqui você pode usar o preferenceID para renderizar informações de status
  return (
    <section>
  
      <StatusScreen
        initialization={initialization}
        onReady={onReady}
        onError={onError}
      />
    </section>
  );
};

export default StatusUpdate;
