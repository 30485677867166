import React, { useEffect } from 'react';
import videoRedirectic from '../assets/video/redirect.mp4';
 

function Redirect(props) {
  const {
    dataMakers,
    dataLinks,
    dataArb,

  } = props;
  useEffect(() => {

      setTimeout(()=>{
        window.location.href = dataArb['url_bet'];
      }, 3000);
  }, []);


  return ( 
    <div>
      <style>
        {`
          body{
            overflow: hidden;
            background-color: #2b950d;
          }
        `}
      </style>
      <div id="navigation" data-markers={dataMakers} data-links={dataLinks}>
      </div>
      
      <div>
      <video className='video-background'  autoPlay muted loop>
        <source src={videoRedirectic} type="video/mp4" />
        Seu navegador não suporta a tag de vídeo.
      </video>
    </div>
    </div>
  );
}


export default Redirect;